import { Input, Row, Space } from "antd";
import React, { useCallback, useState } from "react";
import Filter from "./Filter";
import { SearchOutlined, CloseCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import _ from "lodash";

export const FilterField: React.FC<any> = ({
  onChangeSearch,
  setFilterText,
  pageSize,
  sortOrder,
  sortField,
  setFormFields,
  formFields,
  setFilterOptions,
}) => {
  const [searchText, setSearchText] = useState("");

  // eslint-disable-next-line
  const debouncedSearch = useCallback(_.debounce(onChangeSearch, 500), [
    onChangeSearch,
  ]);

  const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setSearchText(newValue);
    debouncedSearch(newValue);
  };

  const clearInput = () => {
    setSearchText("");
    debouncedSearch("");
  };

  const handleOnChangeFilter = (filterForm: any) => {
    const normalizedSortOrder =
      sortOrder === "ascend"
        ? "asc"
        : sortOrder === "descend"
        ? "desc"
        : sortOrder;

    let filterQuery = `?page=0&size=${pageSize}&sort=${sortField}&direction=${normalizedSortOrder}&search=${searchText}&groupId=2`;

    if (filterForm.masTeamMember && filterForm.masTeamMember != null) {
      filterQuery += `&masTeamMember=${filterForm.masTeamMember}`;
    }

    if (filterForm.additionalPackage && filterForm.additionalPackage != null) {
      filterQuery += `&additionalPackgeIds=${filterForm.additionalPackage}`;
    }

    if (filterForm.feedbackStatus && filterForm.feedbackStatus != null) {
      filterQuery += `&feedbackStatusIds=${filterForm.feedbackStatus}`;
    }

    if (filterForm.feedbackChannel && filterForm.feedbackChannel != null) {
      filterQuery += `&feedbackChannelIds=${filterForm.feedbackChannel}`;
    }

    if (filterForm.createdAtRange !== undefined) {
      if (filterForm.createdAtRange.length > 0) {
        let startDate = dayjs(filterForm.createdAtRange[0]).format(
          "YYYY-MM-DDT00:00:00"
        );
        let endDate = dayjs(filterForm.createdAtRange[1]).format(
          "YYYY-MM-DDT23:59:59"
        );
        filterQuery += `&startCreatedAt=${startDate}&endCreatedAt=${endDate}`;
      }
    }

    if (filterForm.registeredDateRange !== undefined) {
      if (filterForm.registeredDateRange.length > 0) {
        let startDate = dayjs(filterForm.registeredDateRange[0]).format(
          "YYYY-MM-DD"
        );
        let endDate = dayjs(filterForm.registeredDateRange[1]).format(
          "YYYY-MM-DD"
        );
        filterQuery += `&registeredAtStartDate=${startDate}&registeredAtEndDate=${endDate}`;
      }
    }

    if (filterForm.subscriptionEndDateRange !== undefined) {
      if (filterForm.subscriptionEndDateRange.length > 0) {
        let startDate = dayjs(filterForm.subscriptionEndDateRange[0]).format(
          "YYYY-MM-DDT00:00:00"
        );
        let endDate = dayjs(filterForm.subscriptionEndDateRange[1]).format(
          "YYYY-MM-DDT23:59:59"
        );
        filterQuery += `&subscriptionEndDateStartDate=${startDate}&subscriptionEndDateEndDate=${endDate}`;
      }
    }

    setFilterText(filterQuery);
  };

  return (
    <Row justify="end">
      <Space align="start">
        <Input
          prefix={<SearchOutlined />}
          suffix={
            searchText && (
              <CloseCircleOutlined
                onClick={clearInput}
                style={{ color: "rgba(0,0,0,.45)", cursor: "pointer" }}
              />
            )
          }
          placeholder="Search"
          value={searchText}
          onChange={onSearchChange}
          style={{ maxWidth: 200 }}
        />
        <Filter
          onChangeFilter={handleOnChangeFilter}
          setFormFields={setFormFields}
          formFields={formFields}
          setFilterOptions={setFilterOptions}
        />
      </Space>
    </Row>
  );
};
