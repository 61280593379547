import React, { useEffect, useState } from "react";
import { Avatar, Badge, Card, Col, Row, Space, Table } from "antd";
import ReactApexChart from "react-apexcharts";
import "../AcmDashboard/acm-dashboard.css";
import DashboardService from "../../../services/DashboardService";
import { getProfilePhotoPath } from "../../../utils";

interface TotalSalesCardProps {
  title: string;
  roleName: string;
}

const TotalSalesCard: React.FC<TotalSalesCardProps> = ({ title, roleName }) => {
  const [loading, setLoading] = useState(false);
  const [salesSummary, setSalesSummary] = useState<any>([]);
  const [series, setSeries] = useState<number[]>([0, 0]);
  const [totalAutoPilot, setTotalAutoPilot] = useState<number>(0);
  const [totalPreAutoPilot, setTotalPreAutoPilot] = useState<number>(0);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  // eslint-disable-next-line
  const [options, setOptions] = useState<any>({
    chart: {
      type: "donut",
    },
    stroke: {
      lineCap: "round",
    },
    plotOptions: {
      radialBar: {
        track: {
          background: "#f4f4f4",
        },
        dataLabels: {
          name: {
            show: true,
            fontSize: "14px",
          },
          value: {
            fontWeight: 600,
          },
        },
      },
    },
    labels: ["Auto Pilot", "Pre-Auto Pilot"],
    colors: ["#EEC73B", "#0086FF"],
    responsive: [
      {
        breakpoint: 992,
        options: {
          plotOptions: {
            radialBar: {
              dataLabels: {
                name: {
                  fontSize: "12px",
                },
                value: {
                  fontSize: "12px",
                },
              },
            },
          },
        },
      },
      {
        breakpoint: 768,
        options: {
          plotOptions: {
            radialBar: {
              dataLabels: {
                name: {
                  fontSize: "15px",
                },
                value: {
                  fontSize: "15px",
                },
              },
            },
          },
        },
      },
      // {
      //   breakpoint: 576,
      //   options: {
      //     plotOptions: {
      //       radialBar: {
      //         dataLabels: {
      //           name: {
      //             fontSize: "12px",
      //           },
      //           value: {
      //             fontSize: "12px",
      //           },
      //         },
      //       },
      //     },
      //   },
      // },
      {
        breakpoint: 480,
        options: {
          plotOptions: {
            radialBar: {
              dataLabels: {
                name: {
                  fontSize: "12px",
                },
                value: {
                  fontSize: "12px",
                },
              },
            },
          },
        },
      },
      {
        breakpoint: 380,
        options: {
          chart: {
            width: 100,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const userId = localStorage.getItem("id");

  const fetchSalesSummary = async () => {
    try {
      const summaryResponse = await DashboardService.getSalesSummaryMas(
        `${
          roleName === "ACCOUNT_MANAGEMENT_MANAGER" ||
          roleName === "ACCOUNT_MANAGEMENT_MEMBER"
            ? "ACCOUNT_MANAGEMENT_MEMBER"
            : null
        }`
      );
      const processedSummary = summaryResponse.data.map((member: any) => {
        const autoPilotSales = member.customerList.reduce(
          (total: number, customer: any) =>
            total +
            customer.additionalPackageHistoryList.filter(
              (pkg: any) => pkg.packageId === 3
            ).length,
          0
        );
        const preAutoPilotSales = member.customerList.reduce(
          (total: number, customer: any) =>
            total +
            customer.additionalPackageHistoryList.filter(
              (pkg: any) => pkg.packageId === 6
            ).length,
          0
        );

        return {
          user: member.user,
          autoPilot: autoPilotSales,
          preAutoPilot: preAutoPilotSales,
        };
      });

      setSalesSummary(processedSummary);

      if (roleName === "MAS_TEAM_MANAGER" || roleName === "ACCOUNT_MANAGEMENT_MANAGER") {
        const totalAutoPilot = processedSummary.reduce(
          (sum: number, member: any) => sum + member.autoPilot,
          0
        );
        const totalPreAutoPilot = processedSummary.reduce(
          (sum: number, member: any) => sum + member.preAutoPilot,
          0
        );
        const totalSales = totalAutoPilot + totalPreAutoPilot;
        const autoPilotPercentage = (
          totalSales > 0 ? (totalAutoPilot / totalSales) * 100 : 0
        ).toFixed(2);
        const preAutoPilotPercentage = (
          totalSales > 0 ? (totalPreAutoPilot / totalSales) * 100 : 0
        ).toFixed(2);

        setSeries([
          Number(autoPilotPercentage),
          Number(preAutoPilotPercentage),
        ]);
        setTotalAutoPilot(totalAutoPilot);
        setTotalPreAutoPilot(totalPreAutoPilot);
        // setOptions((prevOptions: any) => ({
        //   ...prevOptions,
        //   labels: [
        //     `Auto Pilot (${autoPilotPercentage.toFixed(2)}%)`,
        //     `Pre-Auto Pilot (${preAutoPilotPercentage.toFixed(2)}%)`,
        //   ],
        // }));
      } else {
        const memberData = processedSummary.find(
          (member: any) => member.user.id === Number(userId)
        );
        const autoPilot = memberData?.autoPilot || 0;
        const preAutoPilot = memberData?.preAutoPilot || 0;
        const totalSales = autoPilot + preAutoPilot;
        const autoPilotPercentage = (
          totalSales > 0 ? (autoPilot / totalSales) * 100 : 0
        ).toFixed(2);
        const preAutoPilotPercentage = (
          totalSales > 0 ? (preAutoPilot / totalSales) * 100 : 0
        ).toFixed(2);

        setSeries([
          Number(autoPilotPercentage),
          Number(preAutoPilotPercentage),
        ]);
        setTotalAutoPilot(autoPilot);
        setTotalPreAutoPilot(preAutoPilot);
        // setOptions((prevOptions: any) => ({
        //   ...prevOptions,
        //   labels: [
        //     `Auto Pilot (${autoPilotPercentage.toFixed(2)}%)`,
        //     `Pre-Auto Pilot (${preAutoPilotPercentage.toFixed(2)}%)`,
        //   ],
        // }));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchSalesSummary();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      title: (
        <p
          style={{
            margin: "0px",
            fontWeight: "bold",
            fontSize:
              windowWidth < 480
                ? "12px"
                : windowWidth < 768
                ? "14px"
                : windowWidth < 992
                ? "12px"
                : "14px",
          }}
        >
          {roleName === "MAS_TEAM_MANAGER" ? "MAS Team Member" : roleName === "ACCOUNT_MANAGEMENT_MANAGER" ? "Account Management Member" : "Member"}
        </p>
      ),
      dataIndex: "user",
      key: "user",
      ellipsis: true,
      width: 220,
      sorter: (a: any, b: any) =>
        a?.user?.firstName?.localeCompare(b?.user?.firstName),
      defaultSortOrder: "ascend" as "ascend",
      render: (text: string, record: any) => (
        <Row
          align={"middle"}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            padding: "0px 0px",
          }}
        >
          <Avatar
            style={{ marginRight: 5 }}
            size={30}
            src={getProfilePhotoPath(record?.member?.profilePhoto || 1)}
          />
          <b
            style={{
              margin: "0px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              fontSize:
                windowWidth < 480
                  ? "12px"
                  : windowWidth < 768
                  ? "14px"
                  : windowWidth < 992
                  ? "12px"
                  : "14px",
            }}
          >
            {record?.user?.firstName} {record?.user?.lastName}
          </b>
        </Row>
      ),
    },
    {
      title: (
        <p
          style={{
            margin: "0px",
            fontWeight: "bold",
            fontSize:
              windowWidth < 480
                ? "12px"
                : windowWidth < 768
                ? "14px"
                : windowWidth < 992
                ? "12px"
                : "14px",
          }}
        >
          Auto Pilot
        </p>
      ),
      dataIndex: "autoPilot",
      key: "autoPilot",
      width: 150,
      align: "center" as "center",
      ellipsis: true,
      sorter: (a: any, b: any) => a?.autoPilot - b?.autoPilot,
      render: (text: string, record: any) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p
            style={{
              margin: "0px 10px 0px 0px",
              whiteSpace: "nowrap",
              width: "12px",
              fontWeight: "bold",
              textAlign: "center",
              fontSize:
                windowWidth < 480
                  ? "12px"
                  : windowWidth < 768
                  ? "14px"
                  : windowWidth < 992
                  ? "12px"
                  : "14px",
            }}
          >
            {text || 0}
          </p>
        </div>
      ),
    },
    {
      title: (
        <p
          style={{
            margin: "0px",
            fontWeight: "bold",
            fontSize:
              windowWidth < 480
                ? "12px"
                : windowWidth < 768
                ? "14px"
                : windowWidth < 992
                ? "12px"
                : "14px",
          }}
        >
          Pre-Auto Pilot
        </p>
      ),
      dataIndex: "preAutoPilot",
      key: "preAutoPilot",
      width: 150,
      align: "center" as "center",
      ellipsis: true,
      sorter: (a: any, b: any) => a?.preAutoPilot - b?.preAutoPilot,
      render: (text: string, record: any) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p
            style={{
              margin: "0px 10px 0px 0px",
              whiteSpace: "nowrap",
              width: "12px",
              fontWeight: "bold",
              textAlign: "center",
              fontSize:
                windowWidth < 480
                  ? "12px"
                  : windowWidth < 768
                  ? "14px"
                  : windowWidth < 992
                  ? "12px"
                  : "14px",
            }}
          >
            {text || 0}
          </p>
        </div>
      ),
    },
  ];

  return (
    <Card bordered={false} className="info-card" loading={loading}>
      <p
        className="title"
        style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
      >
        {title}
      </p>
      <Row gutter={[12, 12]} align="middle" justify="space-between">
        <Col xs={24} sm={13} md={13}>
          <ReactApexChart
            options={options}
            series={series}
            type="radialBar"
            height={200}
          />
        </Col>
        <Col xs={24} sm={11} md={11}>
          <Row
            align="middle"
            justify="center"
            style={{
              margin:
                windowWidth < 480
                  ? "-30px 0px 10px 0px"
                  : windowWidth < 576
                  ? "-20px 0px 10px 0px"
                  : "0px 0px 0px 0px",
            }}
          >
            <Space direction="vertical" size={0}>
              <Row gutter={[3, 3]}>
                <Col
                  xs={12}
                  sm={24}
                  style={{
                    textAlign: windowWidth < 576 ? "left" : "left",
                  }}
                >
                  <Badge
                    color="#EEC73B"
                    style={{ whiteSpace: "nowrap" }}
                    text={
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize:
                            windowWidth < 480
                              ? "12px"
                              : windowWidth < 768
                              ? "14px"
                              : windowWidth < 992
                              ? "12px"
                              : "14px",
                        }}
                      >
                        Auto Pilot: <b>{totalAutoPilot}</b>
                      </span>
                    }
                  />
                </Col>
                <Col
                  xs={12}
                  sm={24}
                  style={{
                    textAlign: windowWidth < 576 ? "right" : "left",
                  }}
                >
                  <Badge
                    color="#0086FF"
                    style={{ whiteSpace: "nowrap" }}
                    text={
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize:
                            windowWidth < 480
                              ? "12px"
                              : windowWidth < 768
                              ? "14px"
                              : windowWidth < 992
                              ? "12px"
                              : "14px",
                        }}
                      >
                        Pre-Auto Pilot: <b>{totalPreAutoPilot}</b>
                      </span>
                    }
                  />
                </Col>
              </Row>
            </Space>
          </Row>
        </Col>
      </Row>
      {(roleName === "MAS_TEAM_MANAGER" || roleName === "ACCOUNT_MANAGEMENT_MANAGER") && (
        <>
          <Row>
            <Col span={24}>
              <Table
                rowKey={(record) => record?.user?.id}
                columns={columns}
                dataSource={salesSummary}
                pagination={false}
                scroll={{ x: "100%", y: 185 }}
                size="small"
                className="dashboard-table"
              />
            </Col>
          </Row>
        </>
      )}
    </Card>
  );
};

export default TotalSalesCard;
