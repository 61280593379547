import React, { useEffect, useState } from "react";
import { Avatar, Badge, Card, Col, Row, Space, Table } from "antd";
import ReactApexChart from "react-apexcharts";
import "../AcmDashboard/acm-dashboard.css";
import DashboardService from "../../../services/DashboardService";
import { getProfilePhotoPath } from "../../../utils";
import dayjs from "dayjs";

interface TotalFilesCardProps {
  title: string;
  roleName: string;
  dateRange: any;
}

const TotalFilesCard: React.FC<TotalFilesCardProps> = ({
  title,
  roleName,
  dateRange,
}) => {
  const [loading, setLoading] = useState(false);
  const [filesSummary, setFilesSummary] = useState<any>([]);
  const [series, setSeries] = useState<number[]>([0, 0]);
  const [totalInProgress, setTotalInProgress] = useState<number>(0);
  const [totalResolved, setTotalResolved] = useState<number>(0);
  const [totalUnavailable, setTotalUnavailable] = useState<number>(0);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  // eslint-disable-next-line
  const [options, setOptions] = useState<any>({
    chart: {
      type: "donut",
    },
    stroke: {
      lineCap: "round",
    },
    plotOptions: {
      radialBar: {
        track: {
          background: "#f4f4f4",
        },
        dataLabels: {
          name: {
            show: true,
            fontSize: "14px",
          },
          value: {
            fontWeight: 600,
          },
        },
      },
    },
    labels: ["Devam Ediyor", "Çözüldü", "Açılamadı"],
    colors: ["#EEC73B", "#0086FF", "#FF4560"],
    responsive: [
      {
        breakpoint: 992,
        options: {
          plotOptions: {
            radialBar: {
              dataLabels: {
                name: {
                  fontSize: "12px",
                },
                value: {
                  fontSize: "12px",
                },
              },
            },
          },
        },
      },
      {
        breakpoint: 768,
        options: {
          plotOptions: {
            radialBar: {
              dataLabels: {
                name: {
                  fontSize: "15px",
                },
                value: {
                  fontSize: "15px",
                },
              },
            },
          },
        },
      },
      // {
      //   breakpoint: 576,
      //   options: {
      //     plotOptions: {
      //       radialBar: {
      //         dataLabels: {
      //           name: {
      //             fontSize: "12px",
      //           },
      //           value: {
      //             fontSize: "12px",
      //           },
      //         },
      //       },
      //     },
      //   },
      // },
      {
        breakpoint: 480,
        options: {
          plotOptions: {
            radialBar: {
              dataLabels: {
                name: {
                  fontSize: "12px",
                },
                value: {
                  fontSize: "12px",
                },
              },
            },
          },
        },
      },
      {
        breakpoint: 380,
        options: {
          chart: {
            width: 100,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fetchFilesSummary = async (startDate: string, endDate: string) => {
    try {
      if (roleName === "SUSPEND_TEAM_MANAGER") {
        const summaryResponse =
          await DashboardService.getTotalFilesForSuspendTeamManager(
            startDate,
            endDate
          );
        const processedSummary = summaryResponse.data.map((item: any) => {
          const user = { memberName: item.memberName };
          const inProgressFiles = item.statusCounts["Devam Ediyor"] || 0;
          const resolvedFiles = item.statusCounts["Çözüldü"] || 0;
          const unavailableFiles = item.statusCounts["Açılamadı"] || 0;

          return {
            user: user,
            inProgress: inProgressFiles,
            resolved: resolvedFiles,
            unavailable: unavailableFiles,
          };
        });

        setFilesSummary(processedSummary);
        const totalInProgress = processedSummary.reduce(
          (acc: any, cur: any) => acc + cur.inProgress,
          0
        );
        const totalResolved = processedSummary.reduce(
          (acc: any, cur: any) => acc + cur.resolved,
          0
        );
        const totalUnavailable = processedSummary.reduce(
          (acc: any, cur: any) => acc + cur.unavailable,
          0
        );

        const totalSales = totalInProgress + totalResolved + totalUnavailable;

        const inProgressPercentage = (
          totalSales > 0 ? (totalInProgress / totalSales) * 100 : 0
        ).toFixed(2);
        const resolvedPercentage = (
          totalSales > 0 ? (totalResolved / totalSales) * 100 : 0
        ).toFixed(2);
        const unavailablePercentage = (
          totalSales > 0 ? (totalUnavailable / totalSales) * 100 : 0
        ).toFixed(2);

        setSeries([
          Number(inProgressPercentage),
          Number(resolvedPercentage),
          Number(unavailablePercentage),
        ]);

        setTotalInProgress(totalInProgress);
        setTotalResolved(totalResolved);
        setTotalUnavailable(totalUnavailable);
      } else {
        const summaryResponse =
          await DashboardService.getTotalFilesForSuspendTeamMember(
            startDate,
            endDate
          );

        const processedSummary = {
          user: summaryResponse.data.memberName,
          inProgress: summaryResponse.data.statusCounts["Devam Ediyor"] || 0,
          resolved: summaryResponse.data.statusCounts["Çözüldü"] || 0,
          unavailable: summaryResponse.data.statusCounts["Açılamadı"] || 0,
        };

        setFilesSummary(processedSummary);

        const totalInProgress = processedSummary.inProgress;
        const totalResolved = processedSummary.resolved;
        const totalUnavailable = processedSummary.unavailable;

        const totalSales = totalInProgress + totalResolved + totalUnavailable;

        const inProgressPercentage = (
          totalSales > 0 ? (totalInProgress / totalSales) * 100 : 0
        ).toFixed(2);
        const resolvedPercentage = (
          totalSales > 0 ? (totalResolved / totalSales) * 100 : 0
        ).toFixed(2);
        const unavailablePercentage = (
          totalSales > 0 ? (totalUnavailable / totalSales) * 100 : 0
        ).toFixed(2);

        setSeries([
          Number(inProgressPercentage),
          Number(resolvedPercentage),
          Number(unavailablePercentage),
        ]);

        setTotalInProgress(totalInProgress);
        setTotalResolved(totalResolved);
        setTotalUnavailable(totalUnavailable);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dateRange.length > 0) {
      if (dateRange[0] && dateRange[1]) {
        let formattedStartDate = dayjs(dateRange[0]).format(
          "YYYY-MM-DDT00:00:00"
        );
        let formattedEndDate = dayjs(dateRange[1]).format(
          "YYYY-MM-DDT23:59:59"
        );
        fetchFilesSummary(formattedStartDate, formattedEndDate);
        setLoading(true);
      }
    }
    // eslint-disable-next-line
  }, [dateRange, roleName]);

  const columns = [
    {
      title: (
        <p
          style={{
            margin: "0px",
            fontWeight: "bold",
            fontSize:
              windowWidth < 480
                ? "12px"
                : windowWidth < 768
                ? "14px"
                : windowWidth < 992
                ? "12px"
                : "14px",
          }}
        >
          Suspend Team Member
        </p>
      ),
      dataIndex: "user",
      key: "user",
      ellipsis: true,
      width: 265,
      sorter: (a: any, b: any) =>
        a?.user?.memberName?.localeCompare(b?.user?.memberName),
      defaultSortOrder: "ascend" as "ascend",
      render: (text: string, record: any) => (
        <Row
          align={"middle"}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            padding: "0px 0px",
          }}
        >
          <Avatar
            style={{ marginRight: 5 }}
            size={30}
            src={getProfilePhotoPath(1)}
          />
          <b
            style={{
              margin: "0px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              fontSize:
                windowWidth < 480
                  ? "12px"
                  : windowWidth < 768
                  ? "14px"
                  : windowWidth < 992
                  ? "12px"
                  : "14px",
            }}
          >
            {record?.user?.memberName}
          </b>
        </Row>
      ),
    },
    {
      title: (
        <p
          style={{
            margin: "0px",
            fontWeight: "bold",
            fontSize:
              windowWidth < 480
                ? "12px"
                : windowWidth < 768
                ? "14px"
                : windowWidth < 992
                ? "12px"
                : "14px",
          }}
        >
          Devam Ediyor
        </p>
      ),
      dataIndex: "inProgress",
      key: "inProgress",
      width: 150,
      align: "center" as "center",
      ellipsis: true,
      sorter: (a: any, b: any) => a?.inProgress - b?.inProgress,
      render: (text: string, record: any) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p
            style={{
              margin: "0px 10px 0px 0px",
              whiteSpace: "nowrap",
              width: "12px",
              fontWeight: "bold",
              textAlign: "center",
              fontSize:
                windowWidth < 480
                  ? "12px"
                  : windowWidth < 768
                  ? "14px"
                  : windowWidth < 992
                  ? "12px"
                  : "14px",
            }}
          >
            {text || 0}
          </p>
        </div>
      ),
    },
    {
      title: (
        <p
          style={{
            margin: "0px",
            fontWeight: "bold",
            fontSize:
              windowWidth < 480
                ? "12px"
                : windowWidth < 768
                ? "14px"
                : windowWidth < 992
                ? "12px"
                : "14px",
          }}
        >
          Çözüldü
        </p>
      ),
      dataIndex: "resolved",
      key: "resolved",
      width: 150,
      align: "center" as "center",
      ellipsis: true,
      sorter: (a: any, b: any) => a?.resolved - b?.resolved,
      render: (text: string, record: any) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p
            style={{
              margin: "0px 10px 0px 0px",
              whiteSpace: "nowrap",
              width: "12px",
              fontWeight: "bold",
              textAlign: "center",
              fontSize:
                windowWidth < 480
                  ? "12px"
                  : windowWidth < 768
                  ? "14px"
                  : windowWidth < 992
                  ? "12px"
                  : "14px",
            }}
          >
            {text || 0}
          </p>
        </div>
      ),
    },
    {
      title: (
        <p
          style={{
            margin: "0px",
            fontWeight: "bold",
            fontSize:
              windowWidth < 480
                ? "12px"
                : windowWidth < 768
                ? "14px"
                : windowWidth < 992
                ? "12px"
                : "14px",
          }}
        >
          Açılamadı
        </p>
      ),
      dataIndex: "unavailable",
      key: "unavailable",
      width: 150,
      align: "center" as "center",
      ellipsis: true,
      sorter: (a: any, b: any) => a?.unavailable - b?.unavailable,
      render: (text: string, record: any) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <p
            style={{
              margin: "0px 10px 0px 0px",
              whiteSpace: "nowrap",
              width: "12px",
              fontWeight: "bold",
              textAlign: "center",
              fontSize:
                windowWidth < 480
                  ? "12px"
                  : windowWidth < 768
                  ? "14px"
                  : windowWidth < 992
                  ? "12px"
                  : "14px",
            }}
          >
            {text || 0}
          </p>
        </div>
      ),
    },
  ];

  return (
    <Card bordered={false} className="info-card" loading={loading}>
      <p
        className="title"
        style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
      >
        {title}
      </p>
      <Row gutter={[12, 12]} align="middle" justify="space-between">
        <Col xs={24} sm={13} md={13}>
          <ReactApexChart
            options={options}
            series={series}
            type="radialBar"
            height={200}
          />
        </Col>
        <Col xs={24} sm={11} md={11}>
          <Row
            align="middle"
            justify="center"
            style={{
              margin:
                windowWidth < 480
                  ? "-30px 0px 10px 0px"
                  : windowWidth < 576
                  ? "-20px 0px 10px 0px"
                  : "0px 0px 0px 0px",
            }}
          >
            <Space direction="vertical" size={0}>
              <Row gutter={[3, 3]}>
                <Col
                  xs={12}
                  sm={24}
                  style={{
                    textAlign: windowWidth < 576 ? "left" : "left",
                  }}
                >
                  <Badge
                    color="#EEC73B"
                    style={{ whiteSpace: "nowrap" }}
                    text={
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize:
                            windowWidth < 480
                              ? "12px"
                              : windowWidth < 768
                              ? "14px"
                              : windowWidth < 992
                              ? "12px"
                              : "14px",
                        }}
                      >
                        Devam Ediyor: <b>{totalInProgress}</b>
                      </span>
                    }
                  />
                </Col>
                <Col
                  xs={12}
                  sm={24}
                  style={{
                    textAlign: windowWidth < 576 ? "right" : "left",
                  }}
                >
                  <Badge
                    color="#0086FF"
                    style={{ whiteSpace: "nowrap" }}
                    text={
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize:
                            windowWidth < 480
                              ? "12px"
                              : windowWidth < 768
                              ? "14px"
                              : windowWidth < 992
                              ? "12px"
                              : "14px",
                        }}
                      >
                        Çözüldü: <b>{totalResolved}</b>
                      </span>
                    }
                  />
                </Col>
                <Col
                  xs={12}
                  sm={24}
                  style={{
                    textAlign: windowWidth < 576 ? "right" : "left",
                  }}
                >
                  <Badge
                    color="#FF4560"
                    style={{ whiteSpace: "nowrap" }}
                    text={
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize:
                            windowWidth < 480
                              ? "12px"
                              : windowWidth < 768
                              ? "14px"
                              : windowWidth < 992
                              ? "12px"
                              : "14px",
                        }}
                      >
                        Açılamadı: <b>{totalUnavailable}</b>
                      </span>
                    }
                  />
                </Col>
              </Row>
            </Space>
          </Row>
        </Col>
      </Row>
      {roleName === "SUSPEND_TEAM_MANAGER" && (
        <>
          <Row>
            <Col span={24}>
              <Table
                rowKey={(record) => record?.memberName}
                columns={columns}
                dataSource={filesSummary}
                pagination={false}
                scroll={{ x: "100%", y: 170 }}
                size="small"
                className="dashboard-table"
              />
            </Col>
          </Row>
        </>
      )}
    </Card>
  );
};

export default TotalFilesCard;
