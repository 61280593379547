import React, { useEffect, useState } from "react";
import { Avatar, Card, Col, Row, Table } from "antd";
// import ReactApexChart from "react-apexcharts";
import "../AcmDashboard/acm-dashboard.css";
import DashboardService from "../../../services/DashboardService";
import { getProfilePhotoPath } from "../../../utils";
import dayjs from "dayjs";
import goldIcon from "../../../assets/gold.svg";
import silverIcon from "../../../assets/silver.svg";
import bronzIcon from "../../../assets/bronz.svg";
import UserResolvedFilesDetailsModal from "./UserResolvedFilesDetailsModal";

interface TotalResolvedFilesCardProps {
  title: string;
  roleName: string;
  dateRange: any;
}

const TotalResolvedFilesCard: React.FC<TotalResolvedFilesCardProps> = ({
  title,
  roleName,
  dateRange,
}) => {
  const [loading, setLoading] = useState(false);
  const [filesSummary, setFilesSummary] = useState<any>([]);
  const [userDetailsVisible, setUserDetailsVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [selectedUserResolvedFilesDetail, setSelectedUserResolvedFilesDetail] =
    useState<any>(null);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  // eslint-disable-next-line
  // const [options, setOptions] = useState<any>({
  //   chart: {
  //     type: "donut",
  //   },
  //   stroke: {
  //     lineCap: "round",
  //   },
  //   plotOptions: {
  //     radialBar: {
  //       track: {
  //         background: "#f4f4f4",
  //       },
  //       dataLabels: {
  //         name: {
  //           show: true,
  //           fontSize: "15px",
  //         },
  //         value: {
  //           fontWeight: 600,
  //         },
  //       },
  //     },
  //   },
  //   labels: ["Çözüldü"],
  //   colors: ["#EEC73B"],
  //   tooltip: {
  //     enabled: true,
  //     theme: "dark",
  //     y: {
  //       formatter: function (val: number) {
  //         return val.toFixed(2) + "%";
  //       },
  //     },
  //   },
  //   responsive: [
  //     {
  //       breakpoint: 992,
  //       options: {
  //         plotOptions: {
  //           radialBar: {
  //             dataLabels: {
  //               name: {
  //                 fontSize: "12px",
  //               },
  //               value: {
  //                 fontSize: "12px",
  //               },
  //             },
  //           },
  //         },
  //       },
  //     },
  //     {
  //       breakpoint: 768,
  //       options: {
  //         plotOptions: {
  //           radialBar: {
  //             dataLabels: {
  //               name: {
  //                 fontSize: "15px",
  //               },
  //               value: {
  //                 fontSize: "15px",
  //               },
  //             },
  //           },
  //         },
  //       },
  //     },
  //     // {
  //     //   breakpoint: 576,
  //     //   options: {
  //     //     plotOptions: {
  //     //       radialBar: {
  //     //         dataLabels: {
  //     //           name: {
  //     //             fontSize: "12px",
  //     //           },
  //     //           value: {
  //     //             fontSize: "12px",
  //     //           },
  //     //         },
  //     //       },
  //     //     },
  //     //   },
  //     // },
  //     {
  //       breakpoint: 480,
  //       options: {
  //         plotOptions: {
  //           radialBar: {
  //             dataLabels: {
  //               name: {
  //                 fontSize: "12px",
  //               },
  //               value: {
  //                 fontSize: "12px",
  //               },
  //             },
  //           },
  //         },
  //       },
  //     },
  //     {
  //       breakpoint: 380,
  //       options: {
  //         chart: {
  //           width: 100,
  //         },
  //         legend: {
  //           position: "bottom",
  //         },
  //       },
  //     },
  //   ],
  // });

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleUserClick = (user: any) => {
    setSelectedUser(user);
    setSelectedUserResolvedFilesDetail(user?.suspendDetails || null);
    setUserDetailsVisible(true);
  };

  const fetchFilesSummary = async (startDate: string, endDate: string) => {
    setLoading(true);
    try {
      const summaryResponse =
        await DashboardService.getTotalResolvedFilesForSuspendTeam(
          startDate,
          endDate
        );

      const sortedUsers = summaryResponse.data.sort(
        (a: any, b: any) => b.totalFilesCount - a.totalFilesCount
      );

      setFilesSummary(sortedUsers);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (dateRange.length > 0) {
      if (dateRange[0] && dateRange[1]) {
        let formattedStartDate = dayjs(dateRange[0]).format(
          "YYYY-MM-DDT00:00:00"
        );
        let formattedEndDate = dayjs(dateRange[1]).format(
          "YYYY-MM-DDT23:59:59"
        );
        fetchFilesSummary(formattedStartDate, formattedEndDate);
        setLoading(true);
      }
    }
    // eslint-disable-next-line
  }, [dateRange, roleName]);

  const columns = [
    {
      title: (
        <p
          style={{
            margin: "0px",
            fontWeight: "bold",
            fontSize:
              windowWidth < 480
                ? "12px"
                : windowWidth < 768
                ? "14px"
                : windowWidth < 992
                ? "12px"
                : "14px",
          }}
        >
          Suspend Team Member
        </p>
      ),
      dataIndex: "memberName",
      key: "memberName",
      ellipsis: true,
      width: 265,
      sorter: (a: any, b: any) => a?.memberName?.localeCompare(b?.memberName),
      render: (text: string, record: any) => (
        <Row
          align={"middle"}
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            padding: "0px 0px",
          }}
        >
          <Avatar
            style={{ marginRight: 5 }}
            size={30}
            src={getProfilePhotoPath(1)}
          />
          <b
            style={{
              margin: "0px",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              fontSize:
                windowWidth < 480
                  ? "12px"
                  : windowWidth < 768
                  ? "14px"
                  : windowWidth < 992
                  ? "12px"
                  : "14px",
            }}
          >
            {record?.memberName}
          </b>
        </Row>
      ),
    },
    {
      title: (
        <p
          style={{
            margin: "0px",
            fontWeight: "bold",
            fontSize:
              windowWidth < 480
                ? "12px"
                : windowWidth < 768
                ? "14px"
                : windowWidth < 992
                ? "12px"
                : "14px",
          }}
        >
          Toplam Çözülen
        </p>
      ),
      dataIndex: "totalFilesCount",
      key: "totalFilesCount",
      width: 150,
      align: "center" as "center",
      ellipsis: true,
      sorter: (a: any, b: any) => a?.totalFilesCount - b?.totalFilesCount,
      render: (text: string, record: any, index: number) => (
        <div style={{ display: "flex", justifyContent: "center" }}>
          {index < 3 && (
            <img
              src={
                index === 0
                  ? goldIcon
                  : index === 1
                  ? silverIcon
                  : index === 2
                  ? bronzIcon
                  : ""
              }
              alt="icon"
              style={{ marginRight: 16 }}
            />
          )}
          <p
            style={{
              margin: "0px 10px 0px 0px",
              whiteSpace: "nowrap",
              width: "12px",
              fontWeight: "bold",
              textAlign: "right",
              fontSize:
                windowWidth < 480
                  ? "12px"
                  : windowWidth < 768
                  ? "14px"
                  : windowWidth < 992
                  ? "12px"
                  : "14px",
            }}
          >
            {text || 0}
          </p>
        </div>
      ),
    },
  ];

  return (
    <Card bordered={false} className="info-card" loading={loading}>
      <p
        className="title"
        style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
      >
        {title}
      </p>
      {/* <Row gutter={[12, 12]} align="middle" justify="space-between">
        <Col xs={24} sm={13} md={13}>
          <ReactApexChart
            options={options}
            series={series}
            type="radialBar"
            height={200}
          />
        </Col>
        <Col xs={24} sm={11} md={11}>
          <Row
            align="middle"
            justify="center"
            style={{
              margin:
                windowWidth < 480
                  ? "-30px 0px 10px 0px"
                  : windowWidth < 576
                  ? "-20px 0px 10px 0px"
                  : "0px 0px 0px 0px",
            }}
          >
            <Space direction="vertical" size={0}>
              <Row gutter={[3, 3]}>
                <Col
                  xs={12}
                  sm={24}
                  style={{
                    textAlign: windowWidth < 576 ? "right" : "left",
                  }}
                >
                  <Badge
                    color="#EEC73B"
                    style={{ whiteSpace: "nowrap" }}
                    text={
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          fontSize:
                            windowWidth < 480
                              ? "12px"
                              : windowWidth < 768
                              ? "14px"
                              : windowWidth < 992
                              ? "12px"
                              : "14px",
                        }}
                      >
                        Çözüldü: <b>{totalResolved}</b>
                      </span>
                    }
                  />
                </Col>
              </Row>
            </Space>
          </Row>
        </Col>
      </Row> */}
      {/* {roleName === "SUSPEND_TEAM_MANAGER" && ( */}
      <>
        <Row style={{ marginTop: 16 }}>
          <Col span={24}>
            <Table
              rowKey={(record) => record?.memberName}
              columns={columns}
              dataSource={filesSummary}
              pagination={false}
              scroll={{ x: "100%", y: 200 }}
              size="small"
              className="dashboard-table"
              onRow={(record) => {
                return {
                  onClick: () => handleUserClick(record),
                  style: { cursor: "pointer" },
                };
              }}
            />
          </Col>
        </Row>
        <UserResolvedFilesDetailsModal
          visible={userDetailsVisible}
          onClose={() => setUserDetailsVisible(false)}
          userDetails={selectedUser}
          resolvedFilesDetail={selectedUserResolvedFilesDetail}
        />
      </>
      {/* )} */}
    </Card>
  );
};

export default TotalResolvedFilesCard;
