import React from "react";
import { Modal, Card, Row, Col } from "antd";

interface UserResolvedFilesDetailsModalProps {
  visible: boolean;
  onClose: () => void;
  userDetails: any;
  resolvedFilesDetail: any;
}

const UserResolvedFilesDetailsModal: React.FC<UserResolvedFilesDetailsModalProps> = ({
  visible,
  onClose,
  userDetails,
  resolvedFilesDetail,
}) => {
  return (
    <Modal
      title={`Resolved Files Detail for ${userDetails?.memberName}`}
      open={visible}
      onCancel={onClose}
      footer={null}
    >
      {resolvedFilesDetail && resolvedFilesDetail.length > 0 ? (
        <Card>
          <Row
            gutter={[16, 16]}
            style={{
              marginBottom: 10,
              border: "1px solid #eee",
              borderRadius: 5,
              padding: 10,
            }}
          >
            <Col span={12}>
              <strong>Suspend Type</strong>
            </Col>
            <Col span={12} style={{ textAlign: "center" }}>
              <strong>File Count</strong>
            </Col>
          </Row>
          {resolvedFilesDetail.map((item: any) => (
            <Row
              gutter={[16, 16]}
              key={item.suspendType}
              style={{
                marginBottom: "5px",
                border: "1px solid #eee",
                borderRadius: 5,
                padding: 10,
              }}
            >
              <Col span={12}>{item.suspendType}</Col>
              <Col span={12} style={{ textAlign: "center" }}>
                {item.count}
              </Col>
            </Row>
          ))}
        </Card>
      ) : (
        <p>No details of resolved files for this member.</p>
      )}
    </Modal>
  );
};

export default UserResolvedFilesDetailsModal;
