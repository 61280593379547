import { Card, Row, Table, Button, Pagination, Select, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { CopyClipboard } from "../../General/CopyClipboard";
import { StartCall } from "../../General/StartCall";
import { openNotification } from "../../../utils";
import ProspectCustomerService from "../../../services/ProspectCustomerService";
import { useLocation, useNavigate } from "react-router-dom";
import type { PaginationProps } from "antd";
import { FilterField } from "./FilterField";
import DownloadExcel from "../../CustomerDetail/DownloadExcel";
import UserService from "../../../services/UserService";
import ImportExcel from "../../CustomerDetail/ImportExcel";
import moment from "moment";
import NewTask from "../../ProspectCustomerDetail/Tasks/NewTask";
import { ManuelEntry } from "./ManuelEntry";
import StatusService from "../../../services/StatusService";

interface User {
  id: number;
  createdDate: string;
  fullName: string;
  email: string;
  phone: string;
  currentPackage: number;
  status: any;
  activityStatus: string;
  state: string;
  passiveDays: number;
  productCount: number;
  orderCount: number;
  oneamzOrderCount: number;
  freeDays: number;
  lastSalesDate: string;
  sellerLevel: string;
  feedback: number;
  username: string;
  lastLoginDate: string;
  subscriptionStartDate: string;
}

export const ProspectCustomers: React.FC<any> = () => {
  const [users, setUsers] = useState<any[]>([]);
  const [totalCustomerCount, setTotalCustomerCount] = useState(0);
  const roleName = localStorage.getItem("roleName");
  const authUserId = localStorage.getItem("id");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [customerStatusOptions, setCustomerStatusOptions] = useState<any[]>([]);
  const [userOptions, setUserOptions] = useState<any[]>([]);
  const [adminUserOptions, setAdminUsersOptions] = useState<any[]>([]);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const location = useLocation();
  const lastCurrentPage = location.state?.currentPage ?? 0;
  const lastPageSize = location.state?.pageSize ?? 20;
  const lastFormFields = location.state?.formFields ?? {};
  const [formFields, setFormFields] = useState(lastFormFields);
  const [pendingStatusId, setPendingStatusId] = useState<number | null>(null);
  const [filterText, setFilterText] = useState<string>(
    localStorage.getItem("prospectFilterText") ||
      `?page=${lastCurrentPage}&size=${lastPageSize}${
        roleName === "CALL_TEAM" ? `&callCenterMemberId=${authUserId}` : ""
      }`
  );
  const [currentPage, setCurrentPage] = useState<number>(lastCurrentPage);
  const [pageSize, setPageSize] = useState<number>(lastPageSize);
  const navigate = useNavigate();
  const isHomePage = location.pathname === "/";
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filterOptions, setFilterOptions] = useState([]);
  // const [suspendStatusOptions, setSuspendStatusOptions] = useState<any[]>([]);
  const [customerDetailStatusOptions, setCustomerDetailStatusOptions] =
    useState<any[]>([]);

  useEffect(() => {
    if (customerStatusOptions.length === 0) {
      getCustomerStatus();
    }
    // if (suspendStatusOptions.length === 0) {
    //   getSuspendStatus();
    // }
    if (customerDetailStatusOptions.length === 0) {
      getCustomerDetailStatus();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let newFilterText = filterText;
    newFilterText = newFilterText.replace(/(page=)(\d+)/, `$1${currentPage}`);
    newFilterText = newFilterText.replace(/(size=)(\d+)/, `$1${pageSize}`);
    setFilterText(newFilterText);
    // eslint-disable-next-line
  }, [currentPage, pageSize]);

  useEffect(() => {
    const savedFilterText = localStorage.getItem("prospectFilterText");
    if (savedFilterText) {
      setFilterText(savedFilterText);
    } else if (pendingStatusId !== null) {
      const params = new URLSearchParams(filterText);
      params.set("callCenterStatusIds", pendingStatusId.toString());
      setFilterText("?" + params.toString());
    }
    // eslint-disable-next-line
  }, [pendingStatusId]);

  const onChangeSearch = (search: string) => {
    const params = new URLSearchParams(filterText);
    if (search) {
      params.set("search", search);
    } else {
      params.delete("search");
    }
    setCurrentPage(0);
    setPageSize(20);
    const newFilterText = "?" + params.toString();
    setFilterText(newFilterText);
  };

  const getCustomerStatus = () => {
    StatusService.getAll("statusType=CALL_CENTER_STATUS", "&active=true")
      .then((response: any) => {
        let data = response.data;
        const newArray = data.map((item: any) => {
          // "BEKLEYEN" statüsünü kontrol edin
          if (item.name === "BEKLEYEN") {
            setPendingStatusId(item.id);
          }
          return { value: item.id, label: item.name, disabled: !item.active };
        });
        setCustomerStatusOptions(newArray);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const handleChangeStatus = (status: any, id: any) => {
    setIsLoading(true);
    ProspectCustomerService.update(id, { statusId: status })
      .then((response: any) => {
        openNotification("success", "Success", "Changed status");
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const loadFilterTextFromLocalStorage = () => {
      const storedFilterText = localStorage.getItem("prospectFilterText");
      if (storedFilterText) {
        setFilterText(storedFilterText);
      }
    };
    loadFilterTextFromLocalStorage();
  }, []);

  const getUsers = () => {
    setIsLoading(true);
    if (roleName === "CALL_TEAM_MANAGER") {
      ProspectCustomerService.getAll(`${filterText}`)
        .then((response: any) => {
          setUsers(response.data.data);
          setTotalCustomerCount(response.data.totalElements);
          setIsLoading(false);
        })
        .catch((e: Error) => {
          console.log(e);
          setIsLoading(false);
        });
      UserService.getAll("?roleName=CALL_TEAM")
        .then((response: any) => {
          let data = response;
          const newArray = data.map((item: any) => {
            let fullName = `${item.firstName} ${item.lastName}`;
            return { value: item.id, label: fullName };
          });
          setUserOptions(newArray);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    } else if (roleName === "CALL_TEAM") {
      ProspectCustomerService.getAll(`${filterText}`)
        .then((response: any) => {
          setUsers(response.data.data);
          setTotalCustomerCount(response.data.totalElements);
          setIsLoading(false);
        })
        .catch((e: Error) => {
          console.log(e);
          setIsLoading(false);
        });
    } else {
      ProspectCustomerService.getAll(`${filterText}`)
        .then((response: any) => {
          setUsers(response.data.data);
          setTotalCustomerCount(response.data.totalElements);
          setIsLoading(false);
        })
        .catch((e: Error) => {
          console.log(e);
          setIsLoading(false);
        });
      UserService.getAll("?roleName=CALL_TEAM")
        .then((response: any) => {
          let data = response;

          const newArray = data.map((item: any) => {
            let fullName = `${item.firstName} ${item.lastName}`;
            return { value: item.id, label: fullName };
          });

          setUserOptions(newArray);
        })
        .catch((e: Error) => {
          console.log(e);
        });
      if (roleName === "ADMIN") {
        UserService.getAll("?roleName=ADMIN")
          .then((response: any) => {
            let data = response;

            const newArray = data.map((item: any) => {
              let fullName = `${item.firstName} ${item.lastName}`;
              return { value: item.id, label: fullName };
            });

            setAdminUsersOptions(newArray);
          })
          .catch((e: Error) => {
            console.log(e);
          });
      }
    }
  };

  const paginationOnChange: PaginationProps["onChange"] = (page, pageSize) => {
    if (page > 0) {
      setCurrentPage(page - 1);
    }
    setPageSize(pageSize);
  };

  const columns: ColumnsType<User> = [
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
      fixed: "left",
      sorter: true,
      width: 100,
      render: (text: string, record: any) => (
        <p style={{ fontWeight: "bolder", fontSize: 12 }}>
          {record.source?.name ?? "-"}
        </p>
      ),
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      fixed: "left",
      align: "center",
      sorter: true,
      width: 130,
      render: (text: string, record: any) => {
        const hoursBetween = record.hoursBetween;
        return (
          <p
            style={{ fontWeight: "bolder", fontSize: 12, textAlign: "center" }}
          >
            {text ? moment(text).format("DD/MM/YYYY HH:mm") : ""}
            {record.callCenterStatus1?.name === "BEKLEYEN" ? (
              <>
                <br />
                {hoursBetween > 2 ? (
                  <Tag color="red">{hoursBetween} hours ago</Tag>
                ) : (
                  <span>{hoursBetween} hours ago</span>
                )}
              </>
            ) : null}
          </p>
        );
      },
    },
    {
      title: "Customer",
      dataIndex: "fullName",
      key: "fullName",
      fixed: "left",
      ellipsis: true,
      sorter: true,
      width: 250,
      render: (
        text: string,
        record: {
          phone: string;
          email: string;
        }
      ) => (
        <>
          <div
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <CopyClipboard text={text} />
          </div>
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <CopyClipboard text={record.email} />
          </span>
          <br />
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            <span
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
              }}
            >
              {record.phone}
            </span>{" "}
            <StartCall text={record.phone} />
          </span>
        </>
      ),
    },
    {
      title: "OneAmz User",
      dataIndex: "oneAmzUserState",
      key: "oneAmzUserState",
      align: "center" as "center",
      sorter: true,
      width: 160,
      render: (_: any, row: any) => {
        return (
          <p>{row.oneAmzUserState !== null ? row.oneAmzUserState : "-"}</p>
        );
      },
    },
    {
      title: "Academy Hi5 User",
      dataIndex: "academyHi5UserState",
      key: "academyHi5UserState",
      align: "center" as "center",
      sorter: true,
      width: 160,
      render: (_: any, row: any) => {
        return (
          <p>{row.academyHi5UserState !== null ? row.academyHi5UserState : "-"}</p>
        )
      }
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      sorter: true,
      width: 160,
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      sorter: true,
      width: 160,
    },
    {
      title: "Status",
      dataIndex: "callCenterStatus1",
      key: "callCenterStatus1",
      width: 300,
      render: (_: any, row: any) => {
        return (
          <Select
            onChange={(selectedStatus) =>
              handleChangeStatus(selectedStatus, row.id)
            }
            options={customerStatusOptions}
            showSearch
            filterOption={(input, option: any) =>
              option.label.toLowerCase().includes(input.toLowerCase())
            }
            key={row.id}
            {...(row?.callCenterStatus1?.id && {
              defaultValue: row.callCenterStatus1.id,
            })}
            placeholder={"Select"}
            style={{ width: "280px" }}
          ></Select>
        );
      },
    },

    ...(roleName === "CALL_TEAM_MANAGER" || roleName === "ADMIN"
      ? [
          {
            title: "Call Team Member",
            dataIndex: "callCenterMember",
            key: "callCenterMember",
            width: 300,
            render: (_: any, row: any) => (
              <Select
                showSearch
                filterOption={(input, option: any) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
                onChange={(selectedUser) =>
                  handeChangeAssigneUser(selectedUser, row.id)
                }
                options={userOptions}
                key={row.id}
                {...(row?.callCenterMember?.id && {
                  defaultValue: row.callCenterMember.id,
                })}
                placeholder={"Select"}
                style={{ width: "280px" }}
              />
            ),
          },
        ]
      : []),
    {
      title: "Call Center Detail Status",
      dataIndex: "callCenterStatusDetail",
      key: "callCenterStatusDetail",
      width: 300,
      render: (_: any, row: any) => (
        <Select
          showSearch
          filterOption={(input, option) =>
            option && option.label && typeof option.label === "string"
              ? option.label.toLowerCase().includes(input.toLowerCase())
              : false
          }
          onChange={(selectedStatus) =>
            handleChangeDetailStatus(selectedStatus, row.id)
          }
          key={row.id}
          defaultValue={
            row.callCenterStatusDetail && row.callCenterStatusDetail.id
          }
          placeholder={"Select"}
          style={{ width: "280px" }}
        >
          {customerDetailStatusOptions.map((mainStatus) => (
            <Select.OptGroup key={mainStatus.id} label={mainStatus.name}>
              {mainStatus.subStatuses.map((subStatus: any) => (
                <Select.Option key={subStatus.id} value={subStatus.id}>
                  {subStatus.name}
                </Select.Option>
              ))}
            </Select.OptGroup>
          ))}
        </Select>
      ),
    },
    {
      title: "Source of Referral",
      dataIndex: "sourceOfReferral",
      key: "sourceOfReferral",
      sorter: true,
      width: 200,
      align: "center",
      render: (text: string) => <>{text || "-"}</>,
    },
    {
      title: "Detail",
      key: "detail",
      width: 100,
      fixed: "right" as const,
      render: (_: any, record: any) => (
        <Row justify="center" align="middle" gutter={[0, 8]}>
          <Button
            type="primary"
            onClick={() => {
              let filterToSave = filterText;
              const regex = /search=[^&]*/g;
              filterToSave = filterToSave.replace(regex, "");
              filterToSave = filterToSave.replace(/^&|&$/g, "");
              localStorage.setItem("prospectFilterText", filterToSave);
              navigate(`/prospect-customers/detail/${record.id}`, {
                state: {
                  currentPage: currentPage,
                  pageSize: pageSize,
                  formFields: formFields,
                  filterText: filterText,
                },
              });
            }}
          >
            Detail
          </Button>
          {roleName !== "SUCCESS_TEAM_REPORTER" ? (
            <NewTask
              customerName={record?.fullName}
              customerId={record?.id}
              setTriggerUpdate={() => {}}
              getTasks={() => {}}
              userOptions={
                roleName === "ADMIN" ? adminUserOptions : userOptions
              }
            />
          ) : null}
        </Row>
      ),
    },
  ];

  const handeChangeAssigneUser = (user: any, id: any) => {
    setIsLoading(true);
    ProspectCustomerService.updateAssigneUser(id, user)
      .then((response: any) => {
        openNotification("success", "Success", "Changed assigne user");
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getCustomerDetailStatus = () => {
    if (roleName === "CALL_TEAM_MANAGER" || roleName === "CALL_TEAM") {
      let mainStatuses: any[] = [];
      let subStatuses: any[] = [];
      StatusService.getAll("statusType=CALL_CENTER_STATUS_DETAIL")
        .then((response: any) => {
          let data = response.data;
          data.forEach((item: any) => {
            if (item.parent === null) {
              mainStatuses.push(item);
            } else {
              subStatuses.push(item);
            }
          });

          let processedData = mainStatuses.map((mainStatus: any) => {
            return {
              ...mainStatus,
              subStatuses: subStatuses.filter(
                (subStatus: any) => subStatus.parent.id === mainStatus.id
              ),
            };
          });
          setCustomerDetailStatusOptions(processedData);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
  };

  const handleChangeDetailStatus = (status: any, id: any) => {
    if (roleName === "CALL_TEAM_MANAGER" || roleName === "CALL_TEAM") {
      setIsLoading(true);
      ProspectCustomerService.updateDetailStatus(id, { statusId: status })
        .then((response: any) => {
          openNotification("success", "Success", "Changed status");
        })
        .catch((e: Error) => {
          console.log(e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  // const getSuspendStatus = () => {
  //   if (roleName === "CALL_TEAM_MANAGER" || roleName === "CALL_TEAM") {
  //     let mainStatuses: any[] = [];
  //     let subStatuses: any[] = [];
  //     StatusService.getAll("statusType=SOFTWARE_SUSPENDED_STATUS")
  //       .then((response: any) => {
  //         let data = response.data;
  //         data.forEach((item: any) => {
  //           if (item.parent === null) {
  //             mainStatuses.push(item);
  //           } else {
  //             subStatuses.push(item);
  //           }
  //         });

  //         let processedData = mainStatuses.map((mainStatus: any) => {
  //           return {
  //             ...mainStatus,
  //             subStatuses: subStatuses.filter(
  //               (subStatus: any) => subStatus.parent.id === mainStatus.id
  //             ),
  //           };
  //         });
  //         setSuspendStatusOptions(processedData);
  //       })
  //       .catch((e: Error) => {
  //         console.log(e);
  //       });
  //   }
  // };

  // const handleChangeSuspendStatus = (status: any, id: any) => {
  //   if (roleName === "CALL_TEAM_MANAGER" || roleName === "CALL_TEAM") {
  //     let data: any = {
  //       suspendStatusId: status,
  //       prospectCustomerIds: [id],
  //     };
  //     setIsLoading(true);
  //     ProspectCustomerService.assignProspectCustomerToSuspendStatus(data)
  //       .then((response: any) => {
  //         openNotification(
  //           "success",
  //           "Success",
  //           "Changed software suspend status"
  //         );
  //       })
  //       .catch((e: Error) => {
  //         console.log(e);
  //       })
  //       .finally(() => {
  //         setIsLoading(false);
  //       });
  //   }
  // };

  const updateFilterSort = (field: string, order: any) => {
    const direction =
      order === "ascend" ? "asc" : order === "descend" ? "desc" : "";
    const sortParamRegex = /&sort=([^&]*)&direction=([^&]*)/;
    const sortMatch = filterText.match(sortParamRegex);
    let newFilterText = filterText;
    if (sortMatch) {
      newFilterText = newFilterText.replace(
        sortParamRegex,
        `&sort=${field}&direction=${direction}`
      );
    } else if (field && direction) {
      newFilterText = `${newFilterText}&sort=${field}&direction=${direction}`;
    }
    setFilterText(newFilterText);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let nextSortOrder = "";
    if (sorter.field !== sortField) {
      nextSortOrder = "ascend";
    } else {
      switch (sortOrder) {
        case "ascend":
          nextSortOrder = "descend";
          break;
        case "descend":
          nextSortOrder = "";
          break;
        default:
          nextSortOrder = "ascend";
      }
    }
    setSortOrder(nextSortOrder);
    setSortField(sorter.field || "");
    updateFilterSort(sorter.field, nextSortOrder);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      getUsers();
    }, 1000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line
  }, [filterText, sortField, sortOrder, pendingStatusId]);

  const handleUploadExcelData = () => {
    getUsers();
  };

  const handleUploadManuelData = () => {
    getUsers();
  };

  return (
    <>
      <Card
        className="card-customers"
        title={
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <div
              className="customer-card-title"
              style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
            >
              Call Center Customers List
            </div>
            <div className="customer-card-info">
              <b>{totalCustomerCount} </b>Call Center Customers
            </div>
          </div>
        }
        extra={
          <Row style={{ minWidth: 400, display: "flex" }}>
            <FilterField
              onChangeSearch={onChangeSearch}
              setFilterText={setFilterText}
              pageSize={pageSize}
              setFormFields={setFormFields}
              formFields={formFields}
              setFilterOptions={setFilterOptions}
              pendingStatusId={pendingStatusId}
            />
            {!isHomePage &&
              (roleName === "CALL_TEAM_MANAGER" ||
                roleName === "SUCCESS_TEAM_MANAGER" ||
                roleName === "ACCOUNT_MANAGER_MANAGER" ||
                roleName === "ADMIN") && (
                <Button
                  type="primary"
                  style={{ marginRight: 16 }}
                  onClick={() => navigate(`/prospect-customers/sales-approval`)}
                >
                  Sales Approval
                </Button>
              )}
            <DownloadExcel filterText={filterText} />
            {roleName === "CALL_TEAM_MANAGER" && (
              <ImportExcel handleUploadExcelData={handleUploadExcelData} />
            )}
            {roleName === "CALL_TEAM_MANAGER" && (
              <ManuelEntry handleUploadManuelData={handleUploadManuelData} />
            )}
          </Row>
        }
        bordered={true}
        style={{ marginTop: "10px" }}
      >
        <Table
          rowKey="id"
          loading={isLoading}
          scroll={{ x: "100%" }}
          sticky
          columns={columns}
          dataSource={users}
          pagination={false}
          onChange={handleTableChange}
        />
        <Row style={{ marginTop: "30px" }} justify={"center"}>
          <Pagination
            showSizeChanger={true}
            total={totalCustomerCount}
            defaultPageSize={pageSize}
            defaultCurrent={0}
            current={currentPage + 1}
            showQuickJumper
            onChange={paginationOnChange}
          />
        </Row>
      </Card>
    </>
  );
};
