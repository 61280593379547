import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
} from "antd";
import UserService from "../../services/UserService";
import RoleService from "../../services/RoleService";
import { IUserResponseData } from "../../types/User";
import { IRoleResponseData } from "../../types/Role";
import { can, openNotification } from "../../utils";
import { IUserGroupResponseData } from "../../types/UserGroup";
import UserGroupService from "../../services/UserGroupService";
import UserPermissionService from "../../services/UserPermissionService";
import { DeleteOutlined } from "@ant-design/icons";

interface UserDetailProps {
  userDetailDrawerOpen: boolean;
  onClose: () => void;
  id: any;
  data: any;
  getUsersWithPagination: () => void;
}

const UserDetail: React.FC<UserDetailProps> = ({
  userDetailDrawerOpen,
  onClose,
  id,
  data,
  getUsersWithPagination,
}) => {
  const [form] = Form.useForm();
  const [userGroupForm] = Form.useForm();
  const [updateSalesTargetForm] = Form.useForm();
  const roleName = localStorage.getItem("roleName");

  const [user, setUser] = useState<IUserResponseData>();
  const [roles, setRoles] = useState<IRoleResponseData[]>([]);
  const [groups, setGroups] = useState<IUserGroupResponseData[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<number[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<number | undefined>(
    undefined
  );
  // eslint-disable-next-line
  const [updateSalesTargetFormLoading, setUpdateSalesTargetFormLoading] =
    useState(false);
  const [userGroupFormLoading, setUserGroupFormLoading] = useState(false);

  useEffect(() => {
    if (data?.permissions?.length > 0) {
      const userGroupId = data.permissions[0].userGroupId;
      userGroupForm.setFieldsValue({ userGroupId });
      setSelectedGroups(userGroupId);
    }
  }, [data, userGroupForm]);

  useEffect(() => {
    if (userDetailDrawerOpen && id) {
      if (roleName === "ADMIN") {
        getRoles();
        getGroups();
      }
      getUser(id);
    }
    // eslint-disable-next-line
  }, [userDetailDrawerOpen, id]);

  const getRoles = () => {
    if (can("roles:getAll")) {
      RoleService.getAll()
        .then((response: any) => {
          setRoles(response.data);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
  };

  const getGroups = () => {
    if (can("roles:getAll")) {
      UserGroupService.getAll()
        .then((response: any) => {
          setGroups(response.data);
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
  };

  const getUser = (userId: number) => {
    if (can("users:get")) {
      UserService.get(userId)
        .then((response: any) => {
          const data = response.data;
          setUser(data);
          console.log("userdata", data);
          updateSalesTargetForm.setFieldsValue({
            salesTarget: data.salesTarget,
          });
        })
        .catch((e: Error) => {
          console.log(e);
        });
    }
  };

  const handleRoleSelectChange = (value: number[]) => {
    setSelectedRoles(value);
  };

  const handleGroupSelectChange = (value: number) => {
    setSelectedGroups(value);
  };

  const updateRoleByUserId = () => {
    if (!id) return;
    UserService.updateRoleByUserId(id, { roleIdList: selectedRoles })
      .then(() => {
        openNotification("success", "Success", "Changed roles");
        getUsersWithPagination();
        onClose();
        setSelectedRoles([]);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const updateGroupByUserId = () => {
    setUserGroupFormLoading(true);
    if (!id) return;
    const userGroupId = selectedGroups ?? 0;
    const userId = id;

    const payload = {
      userGroupId: userGroupId,
      userId: userId,
    };
    if (data.permissions && data.permissions.length === 0) {
      UserPermissionService.create(payload)
        .then(() => {
          openNotification("success", "Success", "Changed group");
          getUsersWithPagination();
          onClose();
        })
        .catch((e: Error) => {
          console.log(e);
        })
        .finally(() => setUserGroupFormLoading(false));
    } else {
      UserPermissionService.update(userId, data.permissions[0].userGroupId, payload)
        .then(() => {
          openNotification("success", "Success", "Changed group");
          getUsersWithPagination();
          onClose();
        })
        .catch((e: Error) => {
          console.log(e);
        })
        .finally(() => setUserGroupFormLoading(false));
    }
  };

  const handleRemoveGroup = () => {
    if (!id || !selectedGroups) return;
    setUserGroupFormLoading(true);

    UserPermissionService.remove(id, selectedGroups)
      .then(() => {
        openNotification("success", "Success", "Group has been removed.");
        userGroupForm.resetFields(["userGroupId"]);
        setSelectedGroups(undefined);
        getUsersWithPagination();
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setUserGroupFormLoading(false));
  };

  const updateSalesTarget = (values: any) => {
    setUpdateSalesTargetFormLoading(true);
    if (!id) return;
    const postObj = {
      salesTarget: values.salesTarget,
    };
    UserService.updateSalesTargetByUserId(id, postObj)
      .then(() => {
        openNotification("success", "Success", "Update sales target");
        updateSalesTargetForm.resetFields();
        getUser(id);
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => setUpdateSalesTargetFormLoading(false));
  };

  return (
    <>
      <Drawer
        title="User Detail"
        placement="right"
        onClose={onClose}
        open={userDetailDrawerOpen}
      >
        <h4 style={{ marginTop: "0px" }}>Fullname</h4>
        <p>
          {data?.firstName} {data?.lastName}
        </p>
        <Divider />
        <h4>Sales Target</h4>
        <Form
          form={updateSalesTargetForm}
          layout="vertical"
          onFinish={updateSalesTarget}
        >
          <Form.Item
            name="salesTarget"
            rules={[
              {
                required: true,
                message: "Please input sales target!",
              },
            ]}
          >
            <Space.Compact style={{ width: "100%" }}>
              <Input
                addonBefore={user?.salesTarget}
                type="number"
                placeholder="New Sales Target"
              />
              <Button
                htmlType="submit"
                loading={updateSalesTargetFormLoading}
                type="primary"
              >
                Update
              </Button>
            </Space.Compact>
          </Form.Item>
        </Form>
        <Divider />
        {roleName === "ADMIN" ? (
          <>
            <h4>Role</h4>
            <Form
              layout="horizontal"
              style={{ maxWidth: 600 }}
              onFinish={updateRoleByUserId}
              form={form}
            >
              <Select
                mode="multiple"
                placeholder={"Select"}
                style={{ width: "100%" }}
                onChange={handleRoleSelectChange}
              >
                {roles.map((option) => (
                  <Select.Option key={option.id} value={option.id}>
                    {option.description}
                  </Select.Option>
                ))}
              </Select>
              <Divider />

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </>
        ) : (
          <></>
        )}
        {roleName === "ADMIN" ? (
          <>
            <div
              className="ant-drawer-header"
              style={{ padding: "0 24px 16px 24px" }}
            >
              <div className="ant-drawer-header-title">
                <div className="ant-drawer-title" style={{ marginLeft: "6px" }}>
                  User Group Detail
                </div>
              </div>
            </div>
            <h4>Group</h4>
            <Form
              layout="horizontal"
              style={{ maxWidth: 600 }}
              onFinish={updateGroupByUserId}
              form={userGroupForm}
            >
              <Row gutter={[16, 16]}>
                <Col xs={20}>
                  <Form.Item name="userGroupId">
                    <Select
                      placeholder="Select"
                      style={{ width: "100%" }}
                      onChange={handleGroupSelectChange}
                    >
                      {groups.map((option) => (
                        <Select.Option key={option.id} value={option.id}>
                          {option.groupName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={2}>
                  <Button
                    danger
                    icon={<DeleteOutlined />}
                    onClick={handleRemoveGroup}
                    disabled={userGroupFormLoading || !selectedGroups}
                  />
                </Col>
              </Row>
              <Divider />

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={userGroupFormLoading}
                >
                  User Group Submit
                </Button>
              </Form.Item>
            </Form>
          </>
        ) : (
          <></>
        )}
      </Drawer>
    </>
  );
};

export default UserDetail;
