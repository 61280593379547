import http from "../http-common";
import { IUserGroupResponseData, IUserGroupRequestData } from "../types/UserGroup";

const getAll = () => {
  return http.get<Array<IUserGroupResponseData>>("/users/groups");
};
const get = (id: number) => {
  return http.get<IUserGroupResponseData>(`/users/groups/${id}`);
};

const create = (data: IUserGroupRequestData) => {
  return http.post<IUserGroupRequestData>("/users/groups", data);
};

const update = (id: any, data: IUserGroupRequestData) => {
  return http.put<any>(`/users/groups/${id}`, data);
};

const remove = (id: any) => {
  return http.delete<any>(`/users/groups/${id}`);
};

const UserGroupService = {
  getAll,
  get,
  create,
  update,
  remove,
};

export default UserGroupService;