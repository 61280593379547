import { Breadcrumb, Card, Col, Collapse, Row, Typography } from "antd";
import { NavLink } from "react-router-dom";
import { ReportsRouter } from "../../ReportsRouter";
import DateRange from "../../../Common/DateRange";
import { useState } from "react";
import { MasTmEarningsReport } from "./MasTmEarningsReport";

const { Title } = Typography;
const { Panel } = Collapse;

const reportPanels = [
  {
    key: "1",
    header: "Pending Earnings Report",
    status: "PENDING",
    component: MasTmEarningsReport,
  },
  {
    key: "2",
    header: "Approved Earnings Report",
    status: "APPROVED",
    component: MasTmEarningsReport,
  },
];

const MasTmEarningsReports = () => {
  const [dateRange, setDateRange] = useState<[string, string]>(["", ""]);
  const [loadedPanels, setLoadedPanels] = useState<string[]>([]);
  const [activePanels, setActivePanels] = useState<string[]>([]);

  const handlePanelChange = (key: string | string[]) => {
    const keyArray = Array.isArray(key) ? key : [key];
    setActivePanels(keyArray);
    setLoadedPanels((prev) => Array.from(new Set([...prev, ...keyArray])));
  };

  return (
    <>
      <Row>
        <Col>
          <Title className="onemaz-page-title" level={3}>
            Reports
          </Title>
          <Breadcrumb
            items={[
              {
                title: <NavLink to="/">Home</NavLink>,
              },
              {
                title: "Earnings Report",
              },
            ]}
          />
        </Col>
      </Row>
      <ReportsRouter />
      <Card
        className="card-customers zebra-table"
        title={
          <>
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <div
                className="customer-card-title"
                style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
              >
                Earnings Report
              </div>
            </div>
          </>
        }
        extra={
          <Row justify={"end"}>
            <DateRange thisMonth={true} onChangeDateRange={setDateRange} />
          </Row>
        }
        bordered
        style={{ marginTop: "30px" }}
      >
        <Collapse
          onChange={handlePanelChange}
          activeKey={activePanels}
          expandIconPosition="end"
        >
          {reportPanels.map(({ key, header, status, component: Component }) => (
            <Panel key={key} header={<h3 style={{ margin: 0 }}>{header}</h3>}>
              {loadedPanels.includes(key) && (
                <Component dateRange={dateRange} status={status} />
              )}
            </Panel>
          ))}
        </Collapse>
      </Card>
    </>
  );
};

export default MasTmEarningsReports;
