import React from "react";
import { Card } from "antd";
import "../AcmDashboard/acm-dashboard.css";

interface InfoCardProps {
  totalCount: number;
  changeCount: number;
  title: string;
  loading: boolean;
}

const InfoCard: React.FC<InfoCardProps> = ({
  totalCount,
  changeCount,
  title,
  loading,
}) => {
  return (
    <Card
      bordered={false}
      className="info-card"
      loading={loading}
      style={{ marginBottom: 0 }}
    >
      <div className="info-card-container">
        <div>
          <p
            className="title"
            style={{
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {title}
          </p>
          <p className="description" style={{ marginBottom: "24px" }}>
            {}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "end",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <p className="count" style={{ textAlign: "left" }}>
              {totalCount}
            </p>
            <p className="count" style={{ textAlign: "right" }}>
              {changeCount}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <p
              className="description"
              style={{
                fontWeight: "bold",
                fontSize: "20px",
                padding: "0px",
                margin: "0px",
                textAlign: "left",
              }}
            >
              {"Total"}
            </p>
            <p
              className="description"
              style={{
                fontWeight: "bold",
                fontSize: "20px",
                padding: "0px",
                margin: "0px 10px",
                textAlign: "right",
              }}
            >
              {"Change"}
            </p>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default InfoCard;
