import http from "../http-common";
import { IAutoAndPreAutoPilotResponse } from "../types/MasTeamReport";

const getAutoAndPreAutoPilotReport = (startDate: string, endDate: string) => {
  return http.get<Array<IAutoAndPreAutoPilotResponse>>(
    `/report/mas-team/summary-report?startDate=${startDate}&endDate=${endDate}`
  );
};

const getEarningReportForManager = (status: string, startDate: string, endDate: string) => {
  return http.get<any>(`/expired-packages/manager?status=${status}&startDate=${startDate}&endDate=${endDate}`);
};

const getEarningReportForMember = (status: string, startDate: string, endDate: string) => {
  return http.get<any>(`/expired-packages?status=${status}&startDate=${startDate}&endDate=${endDate}`);
}

const approveEarning = (id: number) => {
  return http.put<any>(`/expired-packages/${id}/approve`);
}

const MasTeamReportService = {
  getAutoAndPreAutoPilotReport,
  getEarningReportForManager,
  getEarningReportForMember,
  approveEarning
};

export default MasTeamReportService;
