import { useEffect, useState } from "react";
import { Col, Row, Table, Button } from "antd";
import MasTeamReportService from "../../../../services/MasTeamReportService";
import { formatMoney } from "../../../../utils";
import dayjs from "dayjs";
import { ColumnsType } from "antd/es/table";

export const MasTmEarningsReport = ({
  dateRange,
  status,
}: {
  dateRange: [string, string];
  status: string;
}) => {
  const [isLoading, setLoading] = useState(false);
  // eslint-disable-next-line
  const [data, setData] = useState<any[]>([]);
  // eslint-disable-next-line
  const [tableData, setTableData] = useState<any[]>([]);
  const [filteredData, setFilteredData] = useState<any[]>([]);

  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      getReport(dateRange[0], dateRange[1]);
    }
    // eslint-disable-next-line
  }, [dateRange]);

  const getReport = async (startDate: string, endDate: string) => {
    try {
      setLoading(true);

      const formattedStartDate = dayjs(startDate).format("YYYY-MM-DDT00:00:00");
      const formattedEndDate = dayjs(endDate).format("YYYY-MM-DDT23:59:59");

      const response = await MasTeamReportService.getEarningReportForManager(
        status,
        formattedStartDate,
        formattedEndDate
      );

      const filteredResponseData = (response.data || []).filter(
        (item: any) =>
          item.memberName !== "az.success.team.crm@oneamz.com" &&
          item.memberName !== "stm@oneamz.com" &&
          item.memberName !== "duygu.karakilic.hi5acm.crm@oneamz.com"
      );

      setData(filteredResponseData);
      const flattened = flattenData(filteredResponseData);
      setTableData(flattened);
      setFilteredData(flattened);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const flattenData = (originalData: any[]): any[] => {
    return originalData.flatMap((member: any) => {
      return (member.packageGroups || []).map((pkg: any) => ({
        memberName: member.memberName,
        packageName: pkg.packageName,
        totalSales: pkg.reports?.length || 0,
        packageCommission: pkg.packageTotalCommission || 0,
        reports: pkg.reports || [],
        commission: pkg.reports?.commission || 0,
      }));
    });
  };

  const handleApprove = async (reportId: number) => {
    setFilteredData((prev) =>
      prev.map((row) => ({
        ...row,
        reports: row.reports.map((r: any) =>
          r.id === reportId ? { ...r, isLoading: true } : r
        ),
      }))
    );

    try {
      await MasTeamReportService.approveEarning(reportId);
      if (dateRange[0] && dateRange[1]) {
        await getReport(dateRange[0], dateRange[1]);
      }
    } catch (error) {
      console.error("Error approving earning:", error);
    } finally {
      setFilteredData((prev) =>
        prev.map((row) => ({
          ...row,
          reports: row.reports.map((r: any) =>
            r.id === reportId ? { ...r, isLoading: false } : r
          ),
        }))
      );
    }
  };

  const columns: ColumnsType<any> = [
    {
      title: "Member",
      dataIndex: "memberName",
      key: "memberName",
      width: 200,
      ellipsis: true,
      fixed: "left" as "left",
      sorter: (a: any, b: any) => a.memberName.localeCompare(b.memberName),
      defaultSortOrder: "descend" as "descend",
      render: (text: string, record: any) => {
        return (
          <div
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={`${record?.memberName}`}
          >
            <b>{record?.memberName}</b>
          </div>
        );
      },
    },
    {
      title: "Package Name",
      dataIndex: "packageName",
      key: "packageName",
      width: 200,
      align: "center" as "center",
      render: (text: any) => <b>{text}</b>,
    },
    {
      title: "Total Sales",
      dataIndex: "totalSales",
      key: "totalSales",
      width: 100,
      align: "center" as "center",
      render: (val: number) => <b>{val}</b>,
    },
    {
      title: "Total Commission",
      dataIndex: "packageCommission",
      key: "packageCommission",
      width: 100,
      align: "center" as "center",
      render: (val: number) => <b>{formatMoney(val)}</b>,
    },
    {
      title: "Customer Name",
      dataIndex: "reports",
      key: "customers",
      width: 240,
      align: "center" as "center",
      render: (reports: any[]) => {
        return reports.map((report, index) => {
          const isBiggerThanOne = index > 1;
          return (
            <div
              key={report.id}
              style={{
                marginTop: isBiggerThanOne ? "0px" : "12px",
                marginBottom: isBiggerThanOne ? "0px" : "12px",
              }}
            >
              <b>{report.customerName}</b>
            </div>
          );
        });
      },
    },
    {
      title: "Package Commission",
      dataIndex: "reports",
      key: "commission",
      width: 100,
      align: "center" as "center",
      render: (reports: any[]) => {
        return reports.map((report, index) => {
          const isBiggerThanOne = index > 1;
          return (
            <div
              key={report.id}
              style={{
                marginTop: isBiggerThanOne ? "0px" : "12px",
                marginBottom: isBiggerThanOne ? "0px" : "12px",
              }}
            >
              <b>{formatMoney(report.commission)}</b>
            </div>
          );
        });
      },
    },
    // {
    //   title: "Status",
    //   dataIndex: "reports",
    //   key: "status",
    //   width: 30,
    //   align: "center" as "center",
    //   render: (reports: any[]) => {
    //     return reports.map((report, index) => {
    //       const isBiggerThanOne = index > 1;
    //       return report.status === "PENDING" ? (
    //         <Tag
    //           key={report.id}
    //           color="orange"
    //           style={{
    //             marginTop: isBiggerThanOne ? "0px" : "8px",
    //             marginBottom: isBiggerThanOne ? "0px" : "8px",
    //           }}
    //         >
    //           PENDING
    //         </Tag>
    //       ) : (
    //         <Tag
    //           key={report.id}
    //           color="green"
    //           style={{
    //             marginTop: isBiggerThanOne ? "0px" : "8px",
    //             marginBottom: isBiggerThanOne ? "0px" : "8px",
    //           }}
    //         >
    //           {report.status}
    //         </Tag>
    //       );
    //     });
    //   },
    // },
    ...(status === "PENDING"
      ? [
          {
            title: "Action",
            dataIndex: "reports",
            key: "action",
            width: 90,
            align: "center" as "center",
            render: (reports: any[]) => {
              return reports.map((report, index) => {
                const isBiggerThanOne = index > 1;
                return report.status === "PENDING" ? (
                  <Button
                    key={report.id}
                    type="primary"
                    onClick={() => handleApprove(report.id)}
                    loading={report.isLoading}
                    style={{
                      marginTop: isBiggerThanOne ? "0px" : "4px",
                      marginBottom: isBiggerThanOne ? "0px" : "4px",
                    }}
                  >
                    Approve
                  </Button>
                ) : null;
              });
            },
          },
        ]
      : []),
  ];

  return (
    <Row gutter={[16, 16]} style={{ marginBottom: 40, marginTop: 40 }}>
      <Col span={24}>
        <Table
          loading={isLoading}
          dataSource={filteredData}
          columns={columns}
          rowKey={(_, index) => String(index)}
          pagination={false}
          scroll={{ x: "max-content" }}
          size="small"
          tableLayout="fixed"
          sticky
          className="summary-report-table"
        />
      </Col>
    </Row>
  );
};
