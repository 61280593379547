import http from "../http-common";
import { IUserPermissionResponseData, IUserPermissionRequestData } from "../types/UserPermission";

const getAll = () => {
  return http.get<Array<IUserPermissionResponseData>>("/users/permissions");
};
const get = (userId: number) => {
  return http.get<IUserPermissionResponseData>(`/users/permissions/group/${userId}`);
};

const create = (data: IUserPermissionRequestData) => {
  return http.post<IUserPermissionRequestData>("/users/permissions", data);
};

const update = (userId: number, groupId: number, data: IUserPermissionRequestData) => {
  return http.put<any>(`/users/permissions/${userId}/${groupId}`, data);
};

const remove = (userId: number, groupId: number) => {
  return http.delete<any>(`/users/permissions/${userId}/${groupId}`);
};

const UserPermissionService = {
  getAll,
  get,
  create,
  update,
  remove,
};

export default UserPermissionService;