import {
  Card,
  Row,
  Table,
  Button,
  Modal,
  Pagination,
  Select,
  Checkbox,
  Menu,
  Dropdown,
  Tag,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { CopyClipboard } from "../../General/CopyClipboard";
import { StartCall } from "../../General/StartCall";
import { convertDate, formatMoney, openNotification } from "../../../utils";
import CustomerService from "../../../services/CustomerService";
import { useLocation, useNavigate } from "react-router-dom";
import type { PaginationProps } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { FilterField } from "./FilterField";
import DownloadExcel from "../../CustomerDetail/DownloadExcel";
import UserService from "../../../services/UserService";
import goldUser from "./../../../assets/goldUser.svg";
import ReportService from "../../../services/ReportService";
import MultiNote from "./MultiNote";
// import CustomerMultiTask from "./CustomerMultiTask";
import DateRange from "../../Common/DateRange";
import moment from "moment";
import MultiAssign from "./MultiAssign";
import NewTask from "../../UserDetail/Tasks/NewTask";
import StatusService from "../../../services/StatusService";

interface User {
  id: number;
  createdDate: string;
  fullName: string;
  email: string;
  phone: string;
  currentPackage: number;
  status: any;
  activityStatus: string;
  state: string;
  passiveDays: number;
  productCount: number;
  orderCount: number;
  oneamzOrderCount: number;
  freeDays: number;
  lastSalesDate: string;
  sellerLevel: string;
  feedback: number;
  username: string;
  goldUser: boolean;
  lastLoginDate: string;
  subscriptionStartDate: string;
  autoSapSubscriptionRenewalEnabled: boolean;
}

export const MasCustomerList: React.FC<any> = () => {
  const [users, setUsers] = useState<any[]>([]);
  const [totalCustomerCount, setTotalCustomerCount] = useState(0);
  const roleName = localStorage.getItem("roleName");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [feedbackStatusOptions, setFeedbackStatusOptions] = useState<any[]>([]);
  const [feedbackChannelOptions, setFeedbackChannelOptions] = useState<any[]>([]);
  const [adminUserOptions, setAdminUserOptions] = useState<any[]>([]);
  const location = useLocation();
  const lastCurrentPage = location.state?.currentPage ?? 0;
  const lastPageSize = location.state?.pageSize ?? 20;
  const lastSortField = location.state?.sortField ?? "autoSapSubscriptionRenewalEnabled";
  const lastSortOrder = location.state?.sortOrder ?? "desc";
  const lastFilterText =
    location.state?.filterText ??
    `?page=${lastCurrentPage}&size=${lastPageSize}&sort=${lastSortField}&direction=${lastSortOrder}`;
  const lastFormFields = location.state?.formFields ?? {};
  const [formFields, setFormFields] = useState(lastFormFields);
  const [filterText, setFilterText] = useState<string>(
    localStorage.getItem("masCustomerFilterText") || lastFilterText
  );
  const [currentPage, setCurrentPage] = useState<number>(lastCurrentPage);
  const [pageSize, setPageSize] = useState<number>(lastPageSize);
  const [sortField, setSortField] = useState(lastSortField);
  const [sortOrder, setSortOrder] = useState(lastSortOrder);
  const authUserId = localStorage.getItem("id");
  const [data, setData] = useState<any>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();
  const isHomePage = location.pathname === "/";
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [filterOptions, setFilterOptions] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);
  const [masTeamMembers, setMasTeamMembers] = useState<any[]>([]);
  const [dateRange, setDateRange] = useState<any>([]);
  const [triggerUpdate, setTriggerUpdate] = useState(0);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (dateRange[0] !== undefined && dateRange[1] !== undefined) {
      getReport(dateRange[0], dateRange[1]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  useEffect(() => {
    if (feedbackStatusOptions.length === 0) {
      getFeedbackStatus();
    }
    if (feedbackChannelOptions.length === 0) {
      getFeedbackChannel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let newFilterText = filterText;
    newFilterText = newFilterText.replace(/(page=)(\d+)/, `$1${currentPage}`);
    newFilterText = newFilterText.replace(/(size=)(\d+)/, `$1${pageSize}`);
    setFilterText(newFilterText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pageSize]);

  useEffect(() => {
    const loadFilterTextFromLocalStorage = () => {
      const storedFilterText = localStorage.getItem("masCustomerFilterText");
      if (storedFilterText) {
        setFilterText(storedFilterText);
      }
    };
    loadFilterTextFromLocalStorage();
  }, []);

  const onChangeSearch = (search: string) => {
    const params = new URLSearchParams(filterText);
    if (search) {
      params.set("nameOrEmailOrPhone", search);
    } else {
      params.delete("nameOrEmailOrPhone");
    }
    setCurrentPage(0);
    setPageSize(20);
    const newFilterText = "?" + params.toString();
    setFilterText(newFilterText);
  };

  const getFeedbackStatus = () => {
    StatusService.getAll("statusType=MAS_TEAM_FEEDBACK_STATUS", `&active=true`)
      .then((response: any) => {
        let data = response.data;
        const newArray = data.map((item: any) => {
          return { value: item.id, label: item.name, disabled: !item.active };
        });
        setFeedbackStatusOptions(newArray);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const handleChangeFeedbackStatus = (status: any, id: any) => {
    setIsLoading(true);
    CustomerService.updateMasFeedbackStatus(id, { statusId: status })
      .then((response: any) => {
        openNotification("success", "Success", "Changed status");
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getFeedbackChannel = () => {
    StatusService.getAll("statusType=MAS_TEAM_FEEDBACK_CHANNEL", `&active=true`)
      .then((response: any) => {
        let data = response.data;
        const newArray = data.map((item: any) => {
          return { value: item.id, label: item.name, disabled: !item.active };
        });
        setFeedbackChannelOptions(newArray);
      })
      .catch((e: Error) => {
        console.log(e);
      });
  };

  const handleChangeFeedbackChannel = (channel: any, id: any) => {
    setIsLoading(true);
    CustomerService.updateMasFeedbackChannel(id, { statusId: channel })
      .then((response: any) => {
        openNotification("success", "Success", "Changed status");
      })
      .catch((e: Error) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSuspendTeamDepartmentStatusChange = (
    checked: boolean,
    record: any
  ) => {
    setIsLoading(true);
    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.id === record.id ? { ...user, suspendTeam: checked } : user
      )
    );
  
    CustomerService.updateMasSuspendTeamDepartmentStatus(record.id, { suspendTeam: checked })
      .then((response: any) => {
        openNotification("success", "Success", "Changed status");
      })
      .catch((e: Error) => {
        console.log(e);
        setUsers((prevUsers) =>
          prevUsers.map((user) =>
            user.id === record.id ? { ...user, suspendTeam: !checked } : user
          )
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  

  const getUsers = () => {
    setIsLoading(true);
    if (roleName === "MAS_TEAM_MANAGER") {
      CustomerService.getAll(`${filterText}&memberFlag=MAS_TEAM_MEMBER&academyHi5User=true`)
        .then((response: any) => {
          setUsers(response.data.data);
          setTotalCustomerCount(response.data.totalElements);
          setIsLoading(false);
        })
        .catch((e: Error) => {
          console.log(e);
          setIsLoading(false);
        });
      if (masTeamMembers.length === 0) {
        UserService.getAll("roleName=MAS_TEAM_MEMBER")
          .then((response: any) => {
            let data = response;
            const newArray = data.map((item: any) => {
              let fullName = `${item.firstName} ${item.lastName}`;
              return { value: item.id, label: fullName };
            });
            setMasTeamMembers(newArray);
          })
          .catch((e: Error) => {
            console.log(e);
          });
      }
    } else if (roleName === "MAS_TEAM_MEMBER") {
      CustomerService.getAll(
        `${filterText}&memberFlag=MAS_TEAM_MEMBER&masTeamMember=${authUserId}&academyHi5User=true`
      )
        .then((response: any) => {
          setUsers(response.data.data);
          setTotalCustomerCount(response.data.totalElements);
          setIsLoading(false);
        })
        .catch((e: Error) => {
          console.log(e);
          setIsLoading(false);
        });
    } else {
      CustomerService.getAll(`${filterText}&memberFlag=MAS_TEAM_MEMBER&academyHi5User=true`)
        .then((response: any) => {
          setUsers(response.data.data);
          setTotalCustomerCount(response.data.totalElements);
          setIsLoading(false);
        })
        .catch((e: Error) => {
          console.log(e);
          setIsLoading(false);
        });
      if (masTeamMembers.length === 0) {
        UserService.getAll("roleName=MAS_TEAM_MEMBER")
          .then((response: any) => {
            let data = response;
            const newArray = data.map((item: any) => {
              let fullName = `${item.firstName} ${item.lastName}`;
              return { value: item.id, label: fullName };
            });
            setMasTeamMembers(newArray);
          })
          .catch((e: Error) => {
            console.log(e);
          });
      }
      if (adminUserOptions.length === 0) {
        UserService.getAll("roleName=ADMIN")
          .then((response: any) => {
            let data = response;
            const newArray = data.map((item: any) => {
              let fullName = `${item.firstName} ${item.lastName}`;
              return { value: item.id, label: fullName };
            });
            setAdminUserOptions(newArray);
          })
          .catch((e: Error) => {
            console.log(e);
          });
      }
    }
  };

  const paginationOnChange: PaginationProps["onChange"] = (page, pageSize) => {
    if (page > 0) {
      setCurrentPage(page - 1);
    }
    setPageSize(pageSize);
  };

  const columns: ColumnsType<User> = [
    {
      title: "Created Date",
      dataIndex: "createdAt",
      key: "createdAt",
      fixed: "left",
      align: "center",
      sorter: true,
      width: 110,
      render: (text: string) => (
        <p style={{ fontWeight: "bolder", fontSize: 12 }}>
          {convertDate(text)}
        </p>
      ),
    },
    {
      title: "Register Date",
      dataIndex: "registeredAt",
      key: "registeredAt",
      fixed: "left" as "left",
      align: "center" as "center",
      sorter: true,
      width: 110,
      render: (text: string) => {
        const getMonthDifference = (startDate: any) => {
          const start = new Date(startDate);
          const end = new Date();
          const months =
            (end.getFullYear() - start.getFullYear()) * 12 +
            (end.getMonth() - start.getMonth());
          return months;
        };
        const monthsPassed = getMonthDifference(text);
        return (
          <div
            style={{
              fontSize: 12,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <span>{monthsPassed} months</span>
          </div>
        );
      },
    },
    {
      title: "Customer",
      dataIndex: "fullName",
      key: "fullName",
      fixed: "left",
      sorter: true,
      width: 240,
      render: (
        text: string,
        record: {
          phone: string;
          goldUser: boolean;
          email: string;
        }
      ) => (
        <>
          {record.goldUser ? <img src={goldUser} alt="gold-icon" /> : null}
          <div>
            <CopyClipboard text={text}></CopyClipboard>{" "}
          </div>
          <span>
            <CopyClipboard text={record.email}></CopyClipboard>{" "}
          </span>
          <br />
          <span>
            <span>{record.phone}</span> <StartCall text={record.phone} />
          </span>
        </>
      ),
    },
    {
      title: "Assignment Date",
      dataIndex: "masTeamMember",
      key: "masTeamMember",
      align: "center" as "center",
      sorter: true,
      width: 110,
      render: (text: string) => (
        <p style={{ fontWeight: "bolder", fontSize: 12 }}>
          {convertDate(text)}
        </p>
      ),
    },
    {
      title: "Package Info",
      dataIndex: "additionalPackageHistoryList",
      key: "additionalPackageHistoryList",
      align: "center" as "center",
      width: 200,
      sorter: (a: any, b: any) => {
        const aPackages = a.additionalPackageHistoryList.filter(
          (pkg: any) => pkg.packageId === 3 || pkg.packageId === 6
        );
        const bPackages = b.additionalPackageHistoryList.filter(
          (pkg: any) => pkg.packageId === 3 || pkg.packageId === 6
        );

        const aPackageName =
          aPackages.length > 0 ? aPackages[0].packageName : "";
        const bPackageName =
          bPackages.length > 0 ? bPackages[0].packageName : "";

        return aPackageName.localeCompare(bPackageName);
      },
      render: (text: string, record: any) => {
        const relevantPackages = record.additionalPackageHistoryList.filter(
          (pkg: any) => pkg.packageId === 3 || pkg.packageId === 6
        );

        if (relevantPackages.length > 0) {
          const latestPackage = relevantPackages.reduce(
            (latest: any, pkg: any) => {
              return new Date(pkg.endDate) > new Date(latest.endDate)
                ? pkg
                : latest;
            },
            relevantPackages[0]
          );

          return (
            <div key={latestPackage.packageId}>
              <b>{latestPackage.packageName}</b>
              <p style={{ margin: "0px" }}>
                {latestPackage.startDate &&
                  ` (${new Date(
                    latestPackage.startDate
                  ).toLocaleDateString()}`}
                {latestPackage.endDate &&
                  ` - ${new Date(latestPackage.endDate).toLocaleDateString()})`}
              </p>
            </div>
          );
        } else {
          return <p>-</p>;
        }
      },
    },
    ...(roleName !== "MAS_TEAM_MEMBER"
      ? [
          {
            title: "MAS Member",
            dataIndex: "username",
            key: "username",
            width: 300,
            render: (_: any, row: any) => {
              if (roleName === "ADMIN") {
                return (
                  <span>
                    {row.masTeamMember?.firstName} {row.masTeamMember?.lastName}
                  </span>
                );
              } else {
                return (
                  <Select
                    showSearch
                    filterOption={(input, option: any) =>
                      option.label.toLowerCase().includes(input.toLowerCase())
                    }
                    onChange={(selectedUser) =>
                      handleChangeAssigneUser(selectedUser, row.id)
                    }
                    options={masTeamMembers}
                    key={row.id}
                    {...(row?.masTeamMember?.id && {
                      defaultValue: row?.masTeamMember?.id,
                    })}
                    placeholder={"Select"}
                    style={{ width: "280px" }}
                  ></Select>
                );
              }
            },
          },
        ]
      : []),
    {
      title: "Feedback Status",
      dataIndex: "feedbackStatus",
      key: "feedbackStatus",
      width: 300,
      render: (_: any, row: any) => {
        if (roleName === "ADMIN") {
          const statusOption = feedbackStatusOptions.find(
            (option: any) => option.value === row?.feedbackStatus?.id
          );
          return <span>{statusOption ? statusOption?.label : "-"}</span>;
        } else {
          return (
            <Select
              onChange={(selectedStatus) =>
                handleChangeFeedbackStatus(selectedStatus, row.id)
              }
              options={feedbackStatusOptions}
              showSearch
              filterOption={(input, option: any) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              key={row.id}
              {...(row?.feedbackStatus?.id && {
                defaultValue: row.feedbackStatus.id,
              })}
              placeholder={"Select"}
              style={{ width: "280px" }}
            />
          );
        }
      },
    },
    {
      title: "Feedback Channel",
      dataIndex: "feedbackChannel",
      key: "feedbackChannel",
      width: 300,
      render: (_: any, row: any) => {
        if (roleName === "ADMIN") {
          const channelOption = feedbackChannelOptions.find(
            (option: any) => option.value === row?.feedbackChannel?.id
          );
          return <span>{channelOption ? channelOption?.label : "-"}</span>;
        } else {
          return (
            <Select
              onChange={(selectedChannel) =>
                handleChangeFeedbackChannel(selectedChannel, row.id)
              }
              options={feedbackChannelOptions}
              showSearch
              filterOption={(input, option: any) =>
                option.label.toLowerCase().includes(input.toLowerCase())
              }
              key={row.id}
              {...(row?.feedbackChannel?.id && {
                defaultValue: row.feedbackChannel.id,
              })}
              placeholder={"Select"}
              style={{ width: "280px" }}
            />
          );
        }
      },
    },
    {
      title: "Suspend Team Department",
      dataIndex: "suspendTeam",
      key: "suspendTeam",
      align: "center" as "center",
      sorter: true,
      width: 200,
      render: (checked: boolean, record: any) => (
        <Checkbox
          checked={checked}
          onChange={(e) =>
            handleSuspendTeamDepartmentStatusChange(e.target.checked, record)
          }
        >
          {checked ? "Suspended" : "Active"}
        </Checkbox>
      ),
    },
    {
      title: "Subscription End Date",
      dataIndex: "subscriptionEndDate",
      key: "subscriptionEndDate",
      sorter: true,
      width: 130,
      align: "center",
      render: (text: string) => (
        <p style={{ fontWeight: "bolder", fontSize: 12 }}>
          {text ? moment(text).format("DD/MM/YYYY") : ""}
        </p>
      ),
    },
    {
      title: "Auto Subscription Renewal",
      dataIndex: "autoSapSubscriptionRenewalEnabled",
      key: "autoSapSubscriptionRenewalEnabled",
      sorter: true,
      align: "center",
      width: 120,
      render: (text: boolean, record: any) => (
        <Tag
          color={
            record.autoSapSubscriptionRenewalEnabled === true ? "green" : "red"
          }
        >
          {record?.autoSapSubscriptionRenewalEnabled === true ? "Yes" : "No"}
        </Tag>
      ),
    },
    {
      title: "Detail",
      key: "detail",
      width: 120,
      align: "center",
      fixed: "right" as const,
      render: (_: any, record: any) => {
        const menu = (
          <Menu style={{ textAlign: "center" }}>
            <Menu.Item key="1">
              <Button
                type="primary"
                onClick={() => {
                  let filterToSave = filterText;
                  const regex = /nameOrEmailOrPhone=[^&]*/g;
                  filterToSave = filterToSave.replace(regex, "");
                  filterToSave = filterToSave.replace(/^&|&$/g, "");
                  localStorage.setItem("masCustomerFilterText", filterToSave);
                  navigate(`/mas-customers/detail/${record.id}`, {
                    state: {
                      currentPage: currentPage,
                      pageSize: pageSize,
                      formFields: formFields,
                      filterText: filterText,
                    },
                  });
                }}
              >
                Detail
              </Button>
            </Menu.Item>
            <Menu.Item key="2">
              <Button
                type="primary"
                onClick={() => {
                  let filterToSave = filterText;
                  const regex = /nameOrEmailOrPhone=[^&]*/g;
                  filterToSave = filterToSave.replace(regex, "");
                  filterToSave = filterToSave.replace(/^&|&$/g, "");
                  localStorage.setItem("masCustomerFilterText", filterToSave);
                  navigate(`/mas-customers/account-health/${record.id}`, {
                    state: {
                      currentPage: currentPage,
                      pageSize: pageSize,
                      formFields: formFields,
                      filterText: filterText,
                    },
                  });
                }}
              >
                Account Health
              </Button>
            </Menu.Item>
            <Menu.Item key="3">
              <Button
                type="primary"
                onClick={() => {
                  let filterToSave = filterText;
                  const regex = /nameOrEmailOrPhone=[^&]*/g;
                  filterToSave = filterToSave.replace(regex, "");
                  filterToSave = filterToSave.replace(/^&|&$/g, "");
                  localStorage.setItem("masCustomerFilterText", filterToSave);
                  navigate(`/mas-customers/orders/${record.id}`, {
                    state: {
                      currentPage: currentPage,
                      pageSize: pageSize,
                      formFields: formFields,
                      filterText: filterText,
                    },
                  });
                }}
              >
                Orders
              </Button>
            </Menu.Item>
            <Menu.Item key="4">
              <Button
                type="primary"
                onClick={() => {
                  let filterToSave = filterText;
                  const regex = /nameOrEmailOrPhone=[^&]*/g;
                  filterToSave = filterToSave.replace(regex, "");
                  filterToSave = filterToSave.replace(/^&|&$/g, "");
                  localStorage.setItem("masCustomerFilterText", filterToSave);
                  navigate(`/mas-customers/shipments/${record.id}`, {
                    state: {
                      currentPage: currentPage,
                      pageSize: pageSize,
                      formFields: formFields,
                      filterText: filterText,
                    },
                  });
                }}
              >
                Shipments
              </Button>
            </Menu.Item>
            <Menu.Item key="5">
              <Button
                type="primary"
                onClick={() => {
                  let filterToSave = filterText;
                  const regex = /nameOrEmailOrPhone=[^&]*/g;
                  filterToSave = filterToSave.replace(regex, "");
                  filterToSave = filterToSave.replace(/^&|&$/g, "");
                  localStorage.setItem("masCustomerFilterText", filterToSave);
                  navigate(`/mas-customers/profit-and-loss/${record.id}`, {
                    state: {
                      currentPage: currentPage,
                      pageSize: pageSize,
                      formFields: formFields,
                      filterText: filterText,
                    },
                  });
                }}
              >
                Profit & Loss
              </Button>
            </Menu.Item>
            <Menu.Item key="6">
              <NewTask
                customerName={record?.fullName}
                customerId={record?.id}
                setTriggerUpdate={() => {}}
                isCustomer={record?.learner === null}
                userOptions={masTeamMembers}
              />
            </Menu.Item>
          </Menu>
        );
  
        return (
          <Row justify="center" align="middle" gutter={[0, 8]}>
            <Dropdown overlay={menu} trigger={['click']}>
              <Button icon={<MoreOutlined />} />
            </Dropdown>
          </Row>
        );
      },
    },
  ];

  const handleChangeAssigneUser = (user: any, id: any) => {
    if (roleName === "MAS_TEAM_MANAGER") {
      let data: any = {
        userId: user,
      };
      setIsLoading(true);
      CustomerService.updateAssigneUser(id, data)
        .then((response: any) => {
          openNotification("success", "Success", "Changed assigne user");
        })
        .catch((e: Error) => {
          console.log(e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const updateFilterSort = (field: string, order: any) => {
    const direction =
      order === "ascend" ? "asc" : order === "descend" ? "desc" : "";

    const sortField = field;

    const sortParamRegex = /&sort=([^&]*)&direction=([^&]*)/;
    const sortMatch = filterText.match(sortParamRegex);

    let newFilterText = filterText;

    if (sortMatch) {
      newFilterText = newFilterText.replace(
        sortParamRegex,
        `&sort=${sortField}&direction=${direction}`
      );
    } else if (field && direction) {
      newFilterText = `${newFilterText}&sort=${sortField}&direction=${direction}`;
    }

    setFilterText(newFilterText);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    let nextSortOrder = "";

    if (sorter.field !== sortField) {
      nextSortOrder = "ascend";
    } else {
      switch (sortOrder) {
        case "ascend":
          nextSortOrder = "descend";
          break;
        case "descend":
          nextSortOrder = "";
          break;
        default:
          nextSortOrder = "ascend";
      }
    }

    setSortOrder(nextSortOrder);
    setSortField(sorter.field || "");
    const field = sorter.field;
    updateFilterSort(field, nextSortOrder);
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterText, sortField, sortOrder, triggerUpdate]);

  useEffect(() => {
    // ReportService.customerEarningReport(``)
    //     .then((response) => {
    //         const combinedData: any[] = [];
    //         response.data.forEach((item: any) => {
    //             const matchedItem = authUserId == item.userId;
    //             if (matchedItem) {
    //                 combinedData.push({
    //                     ...item,
    //                     user: matchedItem ? matchedItem : null,
    //                 });
    //             }
    //         });
    //         setData(combinedData);
    //     })
  }, []);

  const getReport = (startDate: any, endDate: any) => {
    ReportService.customerEarningReport(
      `?startDate=${startDate}&endDate=${endDate}`
    ).then((response) => {
      const combinedData: any[] = [];
      response.data.forEach((item: any) => {
        // eslint-disable-next-line
        const matchedItem = authUserId == item.user;
        if (matchedItem) {
          combinedData.push({
            ...item,
            user: matchedItem ? matchedItem : null,
          });
        }
      });

      setData(combinedData);
    });
  };

  const earningDataSource = data.map((item: any, index: any) => ({
    key: index,
    oneamzOrder: item.oneamzOrder,
    packageChangedIn30Days: item.packageChangedIn30Days,
    packageChangedIn60Days: item.packageChangedIn60Days,
    packageChangedIn90Days: item.packageChangedIn90Days,
    packageUpgrade: item.packageUpgrade,
    totalCustomer: item.totalCustomer,
  }));

  const earningColumns: ColumnsType<any> = [
    {
      title: "Total Customer",
      dataIndex: "totalCustomer",
      key: "totalCustomer",
      align: "center",
      width: 100,
    },
    {
      title: "Product Count Commission",
      dataIndex: "packageUpgrade",
      key: "packageUpgrade",
      width: 100,
      align: "center",
      render: (text: number) => <p>{formatMoney(text)}</p>,
    },
    {
      title: "30 Days First Sale Comission",
      dataIndex: "packageChangedIn30Days",
      key: "packageChangedIn30Days",
      align: "center",
      render: (text: number) => <p>{formatMoney(text)}</p>,
      width: 100,
    },
    {
      title: "60 Days First Sale Comission",
      dataIndex: "packageChangedIn60Days",
      key: "packageChangedIn60Days",
      align: "center",
      render: (text: number) => <p>{formatMoney(text)}</p>,
      width: 100,
    },
    {
      title: "90 Days First Sale Comission",
      dataIndex: "packageChangedIn90Days",
      key: "packageChangedIn9Days",
      align: "center",
      render: (text: number) => <p>{formatMoney(text)}</p>,
      width: 100,
    },
    {
      title: "OneAmz Order",
      dataIndex: "oneamzOrder",
      key: "oneamzOrder",
      align: "center",
      render: (text: number) => <p>{formatMoney(text ? text * 0.5 : 0)}</p>,
      width: 100,
    },

    {
      title: "Total Earnings",
      dataIndex: "totalEarnings",
      key: "totalEarnings",
      align: "center",
      render: (text: any, record: any) => {
        const productCountCommission = record.packageUpgrade;
        const firstSaleCommission30Days = record.packageChangedIn30Days ?? 0;
        const firstSaleCommission60Days = record.packageChangedIn60Days ?? 0;
        const firstSaleCommission90Days = record.packageChangedIn90Days ?? 0;
        const oneAmzOrderCommission = record.oneamzOrder
          ? record.oneamzOrder * 0.5
          : 0;
        const totalEarnings =
          productCountCommission +
          firstSaleCommission30Days +
          firstSaleCommission60Days +
          firstSaleCommission90Days +
          oneAmzOrderCommission;

        return (
          <p>
            {" "}
            <b>{formatMoney(totalEarnings)}</b>
          </p>
        );
      },
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: any) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
    getCheckboxProps: () => ({
      disabled: true,
    }),
  };

  const selectAll = () => {
    if (selectedRowKeys.length === totalCustomerCount) {
      setSelectedRowKeys([]);
    } else {
      setIsLoading(true);
      if (roleName === "MAS_TEAM_MANAGER") {
        CustomerService.getAllCustomersLite(`?${filterText}`)
          .then((response: any) => {
            let data = response.data;
            let customerIds = data.map((user: any) => user.id);
            setSelectedRowKeys(customerIds);
            setIsLoading(false);
          })
          .catch((e: Error) => {
            console.log(e);
            setIsLoading(false);
          });
      } else if (roleName === "MAS_TEAM_MEMBER") {
        CustomerService.getAllCustomersLiteMe(`?${filterText}`)
          .then((response: any) => {
            let data = response.data;
            let customerIds = data.map((user: any) => user.id);
            setSelectedRowKeys(customerIds);
            setIsLoading(false);
          })
          .catch((e: Error) => {
            console.log(e);
            setIsLoading(false);
          });
      }
    }
  };

  const onSelectRow = (record: any) => {
    const newSelectedRowKeys = selectedRowKeys.includes(record.id)
      ? selectedRowKeys.filter((key: any) => key !== record.id)
      : [...selectedRowKeys, record.id];

    setSelectedRowKeys(newSelectedRowKeys);
  };

  return (
    <>
      <Card
        className="card-customers"
        title={
          <div style={{ marginTop: 20, marginBottom: 20 }}>
            <>
              <div
                className="customer-card-title"
                style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
              >
                Customer List
              </div>
              <div className="customer-card-info">
                <b>{totalCustomerCount} </b>Customers{" "}
              </div>
            </>
            {roleName === "MAS_TEAM_MANAGER" ||
            roleName === "MAS_TEAM_MEMBER" ? (
              <Button
                style={{ marginTop: 10, marginLeft: 10 }}
                onClick={() => {
                  selectAll();
                }}
                type="primary"
              >
                Select All
              </Button>
            ) : null}
          </div>
        }
        extra={
          <Row style={{ minWidth: 400, display: "flex" }}>
            {isHomePage && roleName === "MAS_TEAM_MEMBER" ? (
              <>
                {selectedRowKeys.length === 0 && (
                  <Button
                    type="primary"
                    onClick={showModal}
                    style={{ marginRight: 16 }}
                  >
                    Earnings
                  </Button>
                )}
                <Modal
                  title={`This Month Earnings`}
                  width={750}
                  open={isModalVisible}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  footer={null}
                >
                  <Row justify={"end"} style={{ marginBottom: 10 }}>
                    <DateRange onChangeDateRange={setDateRange} />
                  </Row>
                  <Table
                    dataSource={earningDataSource}
                    columns={earningColumns}
                    pagination={false}
                  />
                </Modal>
              </>
            ) : null}
            {selectedRowKeys.length > 0 && roleName === "MAS_TEAM_MANAGER" && (
              <MultiAssign
                selectedRowKeys={selectedRowKeys}
                setTriggerUpdate={setTriggerUpdate}
              />
            )}
            {selectedRowKeys.length > 0 && (
              <MultiNote selectedRowKeys={selectedRowKeys} />
            )}
            {/* {selectedRowKeys.length > 0 && (
              <CustomerMultiTask selectedRowKeys={selectedRowKeys} />
            )} */}
            <FilterField
              onChangeSearch={onChangeSearch}
              setFilterText={setFilterText}
              pageSize={pageSize}
              sortField={sortField}
              sortOrder={sortOrder}
              setFormFields={setFormFields}
              formFields={formFields}
              setFilterOptions={setFilterOptions}
            />
            {!isHomePage &&
              (roleName === "MAS_TEAM_MANAGER" || roleName === "ADMIN") && (
                <Button
                  type="primary"
                  style={{ marginRight: 16 }}
                  onClick={() => navigate(`/customers/sales-approval`)}
                >
                  Sales Approval
                </Button>
              )}
            <DownloadExcel filterText={filterText} />
          </Row>
        }
        bordered={true}
        style={{ marginTop: "10px" }}
      >
        <Table
          rowKey={(record) => record.id}
          sticky
          rowSelection={rowSelection}
          loading={isLoading}
          scroll={{ x: "100%" }}
          columns={columns}
          dataSource={users}
          pagination={false}
          onChange={handleTableChange}
          onRow={(record) => ({
            onClick: () => onSelectRow(record),
            className: !record?.autoSapSubscriptionRenewalEnabled ? "highlight-row-orange" : "",
          })}
        />
        <Row style={{ marginTop: "30px" }} justify={"center"}>
          <Pagination
            showSizeChanger={true}
            total={totalCustomerCount}
            defaultPageSize={pageSize}
            defaultCurrent={0}
            current={currentPage + 1}
            pageSizeOptions={["10", "20", "50", "100", "250"]}
            showQuickJumper
            onChange={paginationOnChange}
          />
        </Row>
      </Card>
    </>
  );
};
