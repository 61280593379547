import { Card, Collapse } from "antd";
import { useState } from "react";
import { MemberTotalEarning } from "./MemberTotalEarning";

const { Panel } = Collapse;

const reportPanels = [
  {
    key: "1",
    header: "Approved Earnings",
    status: "APPROVED",
    component: MemberTotalEarning,
  },
  {
    key: "2",
    header: "Pending Earnings",
    status: "PENDING",
    component: MemberTotalEarning,
  },
];

interface TotalEarningsCardProps {
  title: string;
  dateRange: any;
  roleName: string;
}

const TotalEarningsCard: React.FC<TotalEarningsCardProps> = ({
  title,
  dateRange,
  roleName,
}) => {
  const [loadedPanels, setLoadedPanels] = useState<string[]>([]);
  const [activePanels, setActivePanels] = useState<string[]>([]);

  const handlePanelChange = (key: string | string[]) => {
    const keyArray = Array.isArray(key) ? key : [key];
    setActivePanels(keyArray);
    setLoadedPanels((prev) => Array.from(new Set([...prev, ...keyArray])));
  };

  return (
    <Card
      bordered={false}
      className="card-customers zebra-table"
    >
      <div style={{ marginBottom: 20 }}>
        <p
          className="title"
          style={{ color: "#EEC73B", fontSize: 22, fontWeight: 600 }}
        >
          {title}
        </p>
      </div>
      <Collapse
        onChange={handlePanelChange}
        activeKey={activePanels}
        expandIconPosition="end"
      >
        {reportPanels.map(({ key, header, status, component: Component }) => (
          <Panel key={key} header={<h3 style={{ margin: 0 }}>{header}</h3>}>
            {loadedPanels.includes(key) && (
              <Component dateRange={dateRange} status={status} />
            )}
          </Panel>
        ))}
      </Collapse>
    </Card>
  );
};

export default TotalEarningsCard;
