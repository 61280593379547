import { Breadcrumb, Col, Row, Typography } from "antd";
import "./reviews.css";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import { ReportsRouter} from "../components/Report/ReportsRouter";

const { Title } = Typography;

export const Reports = () => {
  const roleName = localStorage.getItem("roleName");

  const getBreadcrumbTitle = (role: string | null) => {
    switch (role) {
      case "ACCOUNT_MANAGER_MANAGER":
        return "Account Manager Team Reports";
      case "CALL_TEAM_MANAGER":
        return "Call Team Reports";
      case "SUCCESS_TEAM_MANAGER":
        return "Success Team Reports";
      case "SUSPEND_TEAM_MANAGER":
        return "Suspend Team Reports";
      case "HI5_ACM_MANAGER":
        return "Hi5 Account Manager Team Reports";
      case "MAS_TEAM_MANAGER":
        return "MAS Team Reports";
      case "ACCOUNT_MANAGEMENT_MANAGER":
        return "Account Management Team Reports";
      case "ADMIN":
        return "Admin Reports";
      default:
        return "Reports";
    }
  };

  const breadcrumbTitle = getBreadcrumbTitle(roleName);

  return (
    <motion.div
      initial={{ y: 200, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      exit={{ y: 300, opacity: 0 }}
      transition={{
        type: "spring",
        stiffness: 260,
        damping: 40,
      }}
    >
      <div>
        <Row>
          <Col>
            <Title className="onemaz-page-title" level={3}>
              Reports
            </Title>
            <Breadcrumb
              items={[
                {
                  title: <NavLink to="/">Home</NavLink>,
                },
                {
                  title: breadcrumbTitle,
                },
              ]}
            />
          </Col>
        </Row>
        <ReportsRouter />
      </div>
    </motion.div>
  );
};

