import { Row, Space } from "antd";
import React from "react";
import Filter from "./UserListFilter";

export const UserListFilterField: React.FC<any> = ({
  setFilterText,
  pageSize,
  setFormFields,
  formFields,
}) => {

  const handleOnChangeFilter = (filterForm: any) => {
    let filterQuery = `page=0&size=${pageSize}`;
    if (filterForm.username && filterForm.username != null) {
      filterQuery += `&username=${filterForm.username}`;
    }

    if (filterForm.fullName && filterForm.fullName != null) {
      filterQuery += `&fullName=${filterForm.fullName}`;
    }

    if (filterForm.roleName && filterForm.roleName != null) {
      filterQuery += `&roleName=${filterForm.roleName}`;
    }

    if (filterForm.groupName && filterForm.groupName != null) {
      filterQuery += `&permissionName=${filterForm.groupName}`;
    }

    if (filterForm.active !== undefined) {
      filterQuery += `&active=${filterForm.active}`;
    }

    if (filterForm.autoAssignment !== undefined) {
      filterQuery += `&autoAssignment=${filterForm.autoAssignment}`;
    }

    setFilterText(filterQuery);
  };

  return (
    <Row justify="end" style={{ marginRight: "40px" }}>
      <Space align="start">
        <Filter
          onChangeFilter={handleOnChangeFilter}
          setFormFields={setFormFields}
          formFields={formFields}
        />
      </Space>
    </Row>
  );
};
