import { Row, Col, Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import DashboardService from "../../../services/DashboardService";
import InfoCard from "./InfoCard";
import dayjs from "dayjs";

interface InfoCardsProps {
  roleName: string;
  dateRange: any;
}

const InfoCards: React.FC<InfoCardsProps> = ({ roleName, dateRange }) => {
  const [loading, setLoading] = useState(false);
  const [inputChangeData, setinputChangeData] = useState<any>([]);
  const [suspendTypeChangeData, setsuspendTypeChangeData] = useState<any>([]);
  const [inputChangeDetailsData, setInputChangeDetailsData] = useState<any>([]);
  const [suspendTypeChangeDetailsData, setSuspendTypeChangeDetailsData] =
    useState<any>([]);
  const [inputChangeDetailsModalVisible, setInputChangeDetailsModalVisible] =
    useState(false);
  const [
    suspendTypeChangeDetailsModalVisible,
    setSuspendTypeChangeDetailsModalVisible,
  ] = useState(false);
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fetchChangeSummary = async (startDate: string, endDate: string) => {
    try {
      if (roleName === "SUSPEND_TEAM_MANAGER") {
        const summaryInputChangeResponse =
          await DashboardService.getTotalInputChangeForSuspendTeamManager(
            startDate,
            endDate
          );
        setinputChangeData(summaryInputChangeResponse.data);

        const summarySuspendTypeChangeResponse =
          await DashboardService.getTotalSuspendTypeChangeForSuspendTeamManager(
            startDate,
            endDate
          );
        setsuspendTypeChangeData(summarySuspendTypeChangeResponse.data);
      } else {
        const summaryInputChangeResponse =
          await DashboardService.getTotalInputChangeForSuspendTeamMember(
            startDate,
            endDate
          );
        setinputChangeData(summaryInputChangeResponse.data);
        const summarySuspendTypeChangeResponse =
          await DashboardService.getTotalSuspendTypeChangeForSuspendTeamMember(
            startDate,
            endDate
          );
        setsuspendTypeChangeData(summarySuspendTypeChangeResponse.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChangeDetails = () => {
    setInputChangeDetailsData(inputChangeData.inputTypeCounts);
    setInputChangeDetailsModalVisible(true);
  };

  const handleSuspendTypeChangeDetails = () => {
    setSuspendTypeChangeDetailsData(suspendTypeChangeData.inputTypeCounts);
    setSuspendTypeChangeDetailsModalVisible(true);
  };

  useEffect(() => {
    if (dateRange.length > 0) {
      if (dateRange[0] && dateRange[1]) {
        let formattedStartDate = dayjs(dateRange[0]).format(
          "YYYY-MM-DDT00:00:00"
        );
        let formattedEndDate = dayjs(dateRange[1]).format(
          "YYYY-MM-DDT23:59:59"
        );
        fetchChangeSummary(formattedStartDate, formattedEndDate);
        setLoading(true);
      }
    }
    // eslint-disable-next-line
  }, [dateRange, roleName]);

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col
          xs={24}
          md={12}
          onClick={handleInputChangeDetails}
          style={{ cursor: "pointer" }}
        >
          <InfoCard
            title="Input Change"
            totalCount={inputChangeData.previousTotalCount}
            changeCount={inputChangeData.currentTotalCount}
            loading={loading}
          />
        </Col>
        <Col
          xs={24}
          md={12}
          onClick={handleSuspendTypeChangeDetails}
          style={{ cursor: "pointer" }}
        >
          <InfoCard
            title="Suspend Type Change"
            totalCount={suspendTypeChangeData.previousTotalCount}
            changeCount={suspendTypeChangeData.currentTotalCount}
            loading={loading}
          />
        </Col>
      </Row>
      <Modal
        title="Input Change Details"
        open={inputChangeDetailsModalVisible}
        onCancel={() => setInputChangeDetailsModalVisible(false)}
        footer={null}
      >
        <Table
          dataSource={inputChangeDetailsData}
          columns={[
            {
              title: (
                <p
                  style={{
                    margin: "0px",
                    fontWeight: "bold",
                    fontSize:
                      windowWidth < 480
                        ? "12px"
                        : windowWidth < 768
                        ? "14px"
                        : windowWidth < 992
                        ? "12px"
                        : "14px",
                  }}
                >
                  Input Type
                </p>
              ),
              dataIndex: "name",
              key: "name",
              width: 220,
              ellipsis: true,
              sorter: (a: any, b: any) => a?.name - b?.name,
              defaultSortOrder: "ascend" as "ascend",
              render: (text: string, record: any) => (
                <Row
                  align={"middle"}
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    padding: "0px 0px",
                  }}
                >
                  <b
                    style={{
                      margin: "0px",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      fontSize:
                        windowWidth < 480
                          ? "12px"
                          : windowWidth < 768
                          ? "14px"
                          : windowWidth < 992
                          ? "12px"
                          : "14px",
                    }}
                  >
                    {text}
                  </b>
                </Row>
              ),
            },
            {
              title: (
                <p
                  style={{
                    margin: "0px",
                    fontWeight: "bold",
                    fontSize:
                      windowWidth < 480
                        ? "12px"
                        : windowWidth < 768
                        ? "14px"
                        : windowWidth < 992
                        ? "12px"
                        : "14px",
                  }}
                >
                  File Count
                </p>
              ),
              dataIndex: "count",
              key: "count",
              width: 100,
              align: "center" as "center",
              ellipsis: true,
              sorter: (a: any, b: any) =>
                a?.count - b?.count,
              render: (text: string, record: any) => (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <p
                    style={{
                      margin: "0px",
                      whiteSpace: "nowrap",
                      fontWeight: "bold",
                      textAlign: "center",
                      fontSize:
                        windowWidth < 480
                          ? "12px"
                          : windowWidth < 768
                          ? "14px"
                          : windowWidth < 992
                          ? "12px"
                          : "14px",
                    }}
                  >
                    {record?.count || 0}
                  </p>
                </div>
              ),
            }
          ]}
          rowKey={(record) => record.name}
          pagination={false}
          scroll={{ x: "100%", y: 185 }}
          size="small"
          className="dashboard-table"
        />
      </Modal>
      <Modal
        title="Suspend Type Change Details"
        open={suspendTypeChangeDetailsModalVisible}
        onCancel={() => setSuspendTypeChangeDetailsModalVisible(false)}
        footer={null}
      >
        <Table
          dataSource={suspendTypeChangeDetailsData}
          columns={[
            {
              title: (
                <p
                  style={{
                    margin: "0px",
                    fontWeight: "bold",
                    fontSize:
                      windowWidth < 480
                        ? "12px"
                        : windowWidth < 768
                        ? "14px"
                        : windowWidth < 992
                        ? "12px"
                        : "14px",
                  }}
                >
                  Suspend Type
                </p>
              ),
              dataIndex: "name",
              key: "name",
              width: 220,
              ellipsis: true,
              sorter: (a: any, b: any) => a?.name - b?.name,
              defaultSortOrder: "ascend" as "ascend",
              render: (text: string, record: any) => (
                <Row
                  align={"middle"}
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    padding: "0px 0px",
                  }}
                >
                  <b
                    style={{
                      margin: "0px",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      fontSize:
                        windowWidth < 480
                          ? "12px"
                          : windowWidth < 768
                          ? "14px"
                          : windowWidth < 992
                          ? "12px"
                          : "14px",
                    }}
                  >
                    {text}
                  </b>
                </Row>
              ),
            },
            {
              title: (
                <p
                  style={{
                    margin: "0px",
                    fontWeight: "bold",
                    fontSize:
                      windowWidth < 480
                        ? "12px"
                        : windowWidth < 768
                        ? "14px"
                        : windowWidth < 992
                        ? "12px"
                        : "14px",
                  }}
                >
                  File Count
                </p>
              ),
              dataIndex: "count",
              key: "count",
              width: 100,
              align: "center" as "center",
              ellipsis: true,
              sorter: (a: any, b: any) =>
                a?.count - b?.count,
              render: (text: string, record: any) => (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <p
                    style={{
                      margin: "0px",
                      whiteSpace: "nowrap",
                      fontWeight: "bold",
                      textAlign: "center",
                      fontSize:
                        windowWidth < 480
                          ? "12px"
                          : windowWidth < 768
                          ? "14px"
                          : windowWidth < 992
                          ? "12px"
                          : "14px",
                    }}
                  >
                    {record?.count || 0}
                  </p>
                </div>
              ),
            }
          ]}
          rowKey={(record) => record.name}
          pagination={false}
          scroll={{ x: "100%", y: 185 }}
          size="small"
          className="dashboard-table"
        />
      </Modal>
    </>
  );
};

export default InfoCards;
